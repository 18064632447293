module.exports = ProfileUserEditController;

ProfileUserEditController.$inject = [
    '$rootScope',
    '$scope',
    '$translate',
    'moment',
    'Features',
    'OutletEndpoint',
    'DistributorEndpoint',
    'OccupationEndpoint',
    'UserEndpoint',
    'ContactEndpoint',
    'Notify',
    '$location',
    '$q',
    '_'
];

function ProfileUserEditController(
    $rootScope,
    $scope,
    $translate,
    moment,
    Features,
    OutletEndpoint,
    DistributorEndpoint,
    OccupationEndpoint,
    UserEndpoint,
    ContactEndpoint,
    Notify,
    $location,
    $q,
    _) {
    $scope.activeMode = 'profile';
    $scope.MediaOutlets = null;
    $scope.isMediaHouseChoosen = false;
    $scope.infoSubmit = infoSubmit;

    // Redirect to home if not authorized
    if (!$rootScope.loggedin) {
        return $location.path('/');
    }

    $scope.gender = [{name: 'Female'}, {name: 'Male'}];

    UserEndpoint.get({ id: 'me' }).$promise.then(function (value) {
        $scope.user = value;
        $scope.personalInfo = {
            occupation_id: value.occupation.id,
            mediaOutlet: value.occupation.outlet,
            mediaHouse: value.occupation.distributor,
            description: value.occupation.description
        }
    })

    function infoSubmit() {
        $scope.saveContact($scope.contacts[0]);
        $scope.saveUser($scope.user);

    }

    var loadContacts = function () {
        ContactEndpoint.query({user: 'me'}).$promise.then(function (contacts) {
            _.forEach(contacts, function (contact) {
                // Save the original contact values
                // and use them to track changes
                contact.original = {
                    contact: contact.contact,
                    can_notify: contact.can_notify
                };

                // make inactive by default
                contact.active = false;
            });

            // Grab the user registration email if there are no contacts
            if (contacts.length === 0) {
                UserEndpoint.get({id: 'me'}, function (user) {
                    contacts.push({
                        type: 'phone',
                        contact: 'null',
                        original: user.email,
                        active: false,
                        isLoginEmail: true
                    });
                });
            }

            $scope.contacts = contacts;
        });
    };
    loadContacts();

    $scope.saveContact = function (contact) {
        if (contact.id) {
            return ContactEndpoint.update(contact);

        } else {
            // Enable notifications for new contacts by default
            contact.can_notify = true;
            return ContactEndpoint.save(contact);
        }
    };

    $scope.state = {
        success: false,
        processing: false,
        changingPassword: false,
        password: ''
    };

    $scope.saveUser = function (user) {
        $scope.state.success = false;
        $scope.state.processing = true;

        var userPayload = angular.copy(user);

        // If we're not changing the password, drop that property from payload (just in case.)
        if ($scope.state.changingPassword) {
            userPayload.password = $scope.state.password;
        }

        var update = UserEndpoint.update({ id: 'me' }, userPayload);

        update.$promise.then(function (user) {
            Notify.notify('user_profile.update_success');

            $scope.state.success = true;
            $scope.state.processing = false;

            // Collapse password change form field.
            $scope.state.changingPassword = false;
            $scope.state.password = '';

            $scope.user = user;

            $scope.$emit('event:close');
            $rootScope.$emit('userUpdate');
            $location.url('/user/profile');


        }, function (errorResponse) { // error
            Notify.apiErrors(errorResponse);
            $scope.state.processing = false;
        });
    };

    $scope.cancel = function () {
        $scope.$emit('event:close');
        $location.url('/user/profile');
    };

    $scope.user = UserEndpoint.getFresh({id: 'me'});


}
