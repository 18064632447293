module.exports = [
    '$scope',
    '$rootScope',
    '$location',
    '$translate',
    '$route',
    'RoleEndpoint',
    'TagEndpoint',
    'Notify',
    'PostFilters',
    'PostEndpoint',
    'ConfigEndpoint',
    'ResourceExportEndpoint',
    '$q',
    '_',
function (
    $scope,
    $rootScope,
    $location,
    $translate,
    $route,
    RoleEndpoint,
    TagEndpoint,
    Notify,
    PostFilters,
    PostEndpoint,
    ConfigEndpoint,
    ResourceExportEndpoint,
    $q,
    _
) {

    // Redirect to home if not authorized
    if ($rootScope.hasManageSettingsPermission() === false) {
        return $location.path('/');
    }
    $scope.filters = PostFilters.getFilters();
    $scope.loading = false;
    $scope.resourceExport = resourceExport;
    $scope.resources = ['posts', 'regions', 'wards', 'districts', 'statuses', 'distributors', 'outlets'];
    $scope.resouceToExport = null;

    function resourceExport() {
        Notify.confirm('notify.resource.export').then(function (message) {
            $scope.loading = true;
            var format = 'csv',
                site = ConfigEndpoint.get({ id: 'site' }).$promise,
                exportResource = ResourceExportEndpoint.export({resource: $scope.resouceToExport}),
                resourceName = $scope.resouceToExport.charAt(0).toUpperCase() + $scope.resouceToExport.substr(1).toLowerCase();

            $q.all([site, exportResource]).then(function (response) {
                var filename = response[0].name + '-' + resourceName + '-' + (new Date()).toISOString().substring(0, 10) + '.' + format,
                    data = response[1].data,
                    anchor = document.createElement('a');

                anchor.href = 'data:attachment/' + format + ';charset=utf-8,' + encodeURIComponent(data);
                anchor.download = filename;
                anchor.click();
                anchor.remove();
                $scope.loading = false;
            });
        });
    }
}];
