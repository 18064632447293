module.exports = LoginSkip;

LoginSkip.$inject = [];
function LoginSkip() {
    return {
        restrict: 'E',
        scope: {},
        controller: LoginSkipController,
        templateUrl: 'templates/common/auth/login-skip.html'
    };
}
LoginSkipController.$inject = [
    '$scope',
    'Authentication',
    'PasswordReset',
    'Registration',
    '$location',
    'ConfigEndpoint'
];
function LoginSkipController(
    $scope,
    Authentication,
    PasswordReset,
    Registration,
    $location,
    ConfigEndpoint
) {
    $scope.login = Authentication.openLogin;
    $scope.register = Registration.openRegister;
    $scope.cancel = cancel;
    activate();

    function activate() {
        // If we're already logged in
        if (Authentication.getLoginStatus()) {
            $scope.$parent.closeModal();
        }
    }

    function cancel() {
        $scope.$parent.closeModal();
    }

}
