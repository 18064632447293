/**
 * Auto-complete directive.
 * Use this to autocmplete from the database.
 */

module.exports = Autocomplete;

Autocomplete.$inject = [
    '$timeout'
];

function Autocomplete($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.autocomplete({
                source: scope[attrs.items],
                select: function () {
                    $timeout(function () {
                        element.trigger('input');
                    }, 0);
                }
            });
        }
    };
}
