module.exports = ReportPieChart;

function ReportPieChart() {
    return {
        restrict: 'E',
        scope: {
            filters: '=',
            change: '='
        },
        controller: ReportPieChartController,
        templateUrl: 'templates/main/dashboard/pie-chart-report.html'
    };
}

ReportPieChartController.$inject = ['$scope', '$translate', 'ReportEndpoint', 'd3', '_'];
function ReportPieChartController($scope, $translate, ReportEndpoint, d3, _) {
    $scope.nvd3Id = "pieChart" + $scope.filters.parameters.id;
    $scope.data = [
        // {
        //     values: []
        // }
    ];

    $scope.options = {
        chart: {
            type: 'pieChart',
            height: 450,
            width: 450,
            x: function (d) {
                return d[$scope.filters.xAxisValue];
            },
            y: function (d) {
                return d[$scope.filters.yAxisValue];
            },
            showLabels: true,
            duration: 500,
            donut: true,
            donutRatio: 0.4,
            labelType: "percent",
            labelThreshold: 0.01,
            labelSunbeamLayout: true,
            legend: {
                margin: {
                    top: 5,
                    right: 35,
                    bottom: 5,
                    left: 0
                }
            }
        }
    };

    activate();

    function activate() {
        // whenever the filters changes, update the report
        getDataStats();
        $scope.$on('someEvent', function () {
            getDataStats();
        }, true);
    }

    function getDataStats() {
        ReportEndpoint.get($scope.filters.parameters).$promise.then(function (response) {
            $scope.dataMetadata = response;
            $scope.title = response.title;
            $scope.pngTitle = response.title.split(' ').join('-').toLowerCase();
            $scope.csvName = response.title.split(' ').join('-').toLowerCase() + '.csv';
            $scope.data = response.data;

        });
    }

}
