module.exports = ReportCumulativeLineChart;

function ReportCumulativeLineChart() {
    return {
        restrict: 'E',
        scope: {
            filters: '=',
            change: '='
        },
        controller: ReportCumulativeLineChartController,
        templateUrl: 'templates/main/dashboard/time-chart-report.html'
    };
}

ReportCumulativeLineChartController.$inject = ['$scope', '$translate', 'ReportEndpoint', 'd3', '_'];
function ReportCumulativeLineChartController($scope, $translate, ReportEndpoint, d3, _) {
    $scope.nvd3Id = "timeChart" + $scope.filters.parameters.id;
    $scope.data = [
        {
            values: []
        }
    ];

    $scope.options = {
        chart: {
            type: 'cumulativeLineChart',
            height: 450,
            width: 450,
            margin: {
                top: 20,
                right: 20,
                bottom: 50,
                left: 65
            },
            x: function (d) {
                return d[$scope.filters.xAxisValue];
            },
            y: function (d) {
                return d[$scope.filters.yAxisValue];
            },
            showValues: true,
            showControls: false,
            useInteractiveGuideline: true,
            clipVoronoi: false,
            valueFormat: d3.format('d'),
            transitionDuration: 500,
            xAxis: {
                axisLabel: "X Axis",
                showMaxMin: false,
                staggerLabels: true
            },
            yAxis: {
                axisLabel: "total",
                tickFormat: d3.format('d'),
                axisLabelDistance: -10
            },
            tooltip: {
                contentGenerator: function (data) {
                    return '<h3>' + data['data'][$scope.filters.xAxisValue] + '</h3>' + '<p>' + data['data'][$scope.filters.yAxisValue] + '</p>';
                }
            },
            forceX: 0,
            barColor: d3.scale.category20().range(),
            noData: $translate.instant('graph.no_data')
        }
    };

    activate();

    function activate() {
        // whenever the filters changes, update the report
        getDataStats();
        $scope.$on('someEvent', function () {
            getDataStats();
        }, true);
    }

    function getDataStats() {
        ReportEndpoint.get($scope.filters.parameters).$promise.then(function (response) {
            $scope.dataMetadata = response;
            $scope.title = response.title;
            $scope.pngTitle = response.title.split(' ').join('-').toLowerCase();
            $scope.data[0].values = response.data;
            var obtained_data = response.data;
            var max_value = Math.max.apply(Math, obtained_data.map(function (o) {
                return o.count;
            }));
            $scope.options.chart.yDomain = [
                0, max_value + 2
            ];

        });
    }

}
