module.exports = [
    '$resource',
    '$rootScope',
    'Util',
    '_',
    '$http',
    'CacheFactory',
function (
    $resource,
    $rootScope,
    Util,
    _,
    $http,
    CacheFactory
) {
    var cache;

    if (!(cache = CacheFactory.get('commentsCache'))) {
        cache = new CacheFactory('commentsCache');
    }
    var CommentEndpoint = $resource(Util.apiUrl('/comments/:id'), {
        id: '@id',
        order: 'desc'
    }, {
        query: {
            method: 'GET',
            isArray: false,
            paramSerializer: '$httpParamSerializerJQLike'
        },
        get: {
            method: 'GET',
            transformResponse: function (data /*, header*/) {
                data = angular.fromJson(data);
                return data;
            }
        },
        update: {
            method: 'PUT'
        },
        options: {
            method: 'OPTIONS'
        },
        save: {
            method: 'POST'
        },
        deleteComment: {
            method: 'DELETE'
        }
    });

    $rootScope.$on('event:authentication:logout:succeeded', function () {
        CommentEndpoint.query();
    });

    CommentEndpoint.getFresh = function (params) {
        cache.remove(Util.apiUrl('/posts/' + (params.user || params.id)));
        return CommentEndpoint.get(params);
    };

    CommentEndpoint.invalidateCache = function () {
        return cache.removeAll();
    };

    CommentEndpoint.queryFresh = function (params) {
        cache.removeAll();
        return CommentEndpoint.query(params);
    };

    return CommentEndpoint;

}];
