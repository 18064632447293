module.exports = [
    '$resource',
    '$rootScope',
    'Util',
    '_',
    '$http',
function (
    $resource,
    $rootScope,
    Util,
    _,
    $http
) {

    var WardEndpoint = $resource(Util.apiUrl('/wards/:id'), {
        id: '@id',
        order: 'asc'
    }, {
        query: {
            method: 'GET',
            isArray: false,
            paramSerializer: '$httpParamSerializerJQLike'
        },
        get: {
            method: 'GET',
            transformResponse: function (data /*, header*/) {
                data = angular.fromJson(data);
                return data;
            }
        },
        update: {
            method: 'PUT'
        },
        options: {
            method: 'OPTIONS'
        }
    });

    $rootScope.$on('event:authentication:logout:succeeded', function () {
        WardEndpoint.query();
    });

    return WardEndpoint;

}];
