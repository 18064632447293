module.exports = [
    '_',
    '$q',
    '$translate',
    'FormEndpoint',
    'FormAttributeEndpoint',
    'RESOURCES',
    'DataImportEndpoint',
    'Util',
    function (
        _,
        $q,
        $translate,
        FormEndpoint,
        FormAttributeEndpoint,
        RESOURCES,
        DataImportEndpoint,
        Util
    ) {
        var importData = {
            data: {}
        };
        var DataRetriever = {
            setImportData: function (data) {
                importData.data = data;
            },
            getImportData: function () {
                return importData.data;
            },
            dataMapperInitialData: function (formId, csvId) {
                var tasks = [];
                var resources = _.map(RESOURCES, 'id');
                var isResource = resources.indexOf(formId) != -1;
                if (isResource) {
                    tasks = [
                        DataImportEndpoint.get({
                            id: csvId
                        }).$promise
                    ];
                } else {
                    tasks = [
                        FormEndpoint.get({
                            id: formId
                        }).$promise,
                        FormAttributeEndpoint.query({
                            formId: formId
                        }).$promise,
                        DataImportEndpoint.get({
                            id: csvId
                        }).$promise
                    ];
                }
                return $q.all(tasks).then(function (results) {
                    var form;
                    var csv;

                    if (!isResource) {
                        form = results[0];
                        // Split locations into lat/lon
                        var points = _.chain(results[1])
                            .where({
                                'type': 'point'
                            })
                            .reduce(function (collection, item) {
                                return collection.concat({
                                    key: item.key + '.lat',
                                    label: item.label + ' (Latitude)',
                                    priority: item.priority
                                }, {
                                    key: item.key + '.lon',
                                    label: item.label + ' (Longitude)',
                                    priority: item.priority
                                });
                            }, [])
                            .value();

                        form.attributes = _.chain(results[1])
                            .reject({
                                type: 'point'
                            })
                            .concat(points)
                            // Add in the Post specific mappable fields
                            .push({
                                'key': 'title',
                                'label': $translate.instant('post.modify.form.title'),
                                'priority': 0
                            }, {
                                'key': 'content',
                                'label': $translate.instant('post.modify.form.description'),
                                'priority': 1
                            }, {
                                'key': 'tags',
                                'label': $translate.instant('post.modify.form.categories'),
                                'priority': 2
                            })
                            .sortBy('priority')
                            .value();

                        csv = results[2];
                        csv.maps_to = Util.autoMap(
                            csv.columns,
                            form.attributes,
                            csv.columns.length
                        );
                    } else {
                        csv = results[0];
                        csv.maps_to = csv.columns;
                    }

                    return {
                        form: form,
                        csv: csv
                    };
                });
            }
        };

        return Util.bindAllFunctionsToSelf(DataRetriever);
    }
];
