module.exports = [
    '$scope',
    '$rootScope',
    '$location',
    'initialData',
    '_',
    'RESOURCES',
    function (
        $scope,
        $rootScope,
        $location,
        initialData,
        _,
        RESOURCES
    ) {

        // Redirect to home if not authorized
        if ($rootScope.hasManageSettingsPermission() === false) {
            return $location.path('/');
        }

        // Change layout class
        $rootScope.setLayout('layout-c');
        // Change mode
        $scope.$emit('event:mode:change', 'settings');

        $scope.csv = initialData.csv;

        $scope.required_fields = [];
        $scope.required_fields_map = {};

        if (initialData.form) {
            $scope.form = initialData.form;
            // Change required attribute labels to show required
            _.each($scope.form.attributes, function (attribute, index) {
                if (attribute.required) {
                    $scope.required_fields.push(attribute.key);
                    $scope.required_fields_map[attribute.key] = attribute.label;
                    // @todo Can't use <span></span> here without a filter or falling
                    // back to ng-repat with ng-bind-html
                    //$scope.form.attributes[index].label = attribute.label + '<span class="required"></span>';
                    $scope.form.attributes[index].label = attribute.label + ' [*]';
                }
            });
        } else {
            $scope.form = {
                name: $scope.csv.fixed.form,
                id: $scope.csv.fixed.form
            };
            $scope.form.attributes = _.map($scope.csv.columns, function (column) {
                return {
                    key: column,
                    label: column
                };
            });
        }
    }
];
