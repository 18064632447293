module.exports={
    "app": {
        "by": "by",
        "title": "PFVR",
        "title_description": "Press Freedom Violation Register",
        "can_see_this": "can see this",
        "collections": "Collections",
        "note": "Please note:",
        "register": "Register",
        "publish": "Publish",
        "login_importance":
            "Having an account will enable you to get feedback on incidence you report. Thus we encourge you to create an account or login if you already have one",
        "create_collection": "Create collection",
        "create_new": "Create new",
        "created_by": "Created by {{author}}",
        "documentation": {
            "title": "Documentation",
            "description":
                "Learn how to set up, configure, and manage your PFVR deployment."
        },
        "report_a_bug": {
            "title": "Report a bug",
            "description":
                "Tell us when something doesn't work the way you expected."
        },
        "edit_collection": "Edit collection",
        "features": {
            "title": "Features",
            "description": "See what PFVR can do and how it works."
        },
        "forbidden": "Sorry, you're not allowed to do that.",
        "get_notifications": "Get notifications",
        "turn_off_notifications": "Turn off notifications",
        "more": "More",
        "cancel": "Cancel",
        "clear_filters": "Clear filters",
        "skip": "Skip",
        "save": "Save",
        "submit_incident": "Submit incident",
        "save_and_close": "Save &amp; close",
        "add_and_close": "Add &amp; close",
        "update_and_close": "Update &amp; close",
        "saving": "Saving",
        "settings": "Settings",
        "share": "Share",
        "specific_roles": "Specific roles...",
        "general": "General",
        "surveys": "Forms",
        "add_survey": "Add Form",
        "edit_survey": "Edit Form",
        "data_sources": "Data Sources",
        "import": "Import",
        "export": "Export",
        "users": "Users",
        "edit_user": "Edit User",
        "add_user": "Add User",
        "edit": "Edit",
        "roles": "Roles",
        "edit_role": "Edit Role",
        "add_role": "Add Role",
        "categories": "Incident Type",
        "edit_category": "Edit Category",
        "add_category": "Add Category",
        "plans": "Plans",
        "delete": "Delete",
        "description": "Description",
        "color": "Color",
        "search_users": "Search users",
        "fields": "Fields",
        "apply_filters": "Apply filters",
        "show_more_less": "Show more/less",
        "filters": "Filters",
        "configure": "Configure",
        "post": "Post",
        "name": "Name",
        "key": "Key",
        "default_value": "Default value",
        "fields": "Fields",
        "tasks": "Tasks",
        "return_to_all_posts": "Return to all posts",
        "this_deplyment_is_private": "This deployment is private",
        "private_deployment": "Private Deployment",
        "mark_as": "Mark as"
    },
    "toolbar": {
        "searchbar": {
            "search_entity": "Search {{entity}}",
            "search_all_entities_for": "Search all {{entity}} for "
        }
    },
    "color_picker": {
        "hex_value": "Hex Value",
        "red": "Red",
        "gold": "Gold",
        "blue": "Blue",
        "green": "Green"
    },
    "feature_limits": {
        "view_unavailable":
            "The {{value}} view isn't available on your current plan. <a href=\"/settings/plan\">Upgrade your plan</a> to get it!"
    },
    "limit": {
        "plan_limit": "PFVR Plan Limit",
        "post_limit_reached":
            "You have reached the limit of published Posts for your plan.",
        "admin_limit_reached":
            "You have reached the limit of admin users for your plan.",
        "post_type_limit_reached":
            "You have reached the forms limit for your plan.",
        "upgrade": "Upgrade"
    },
    "activity": {
        "activity_over_time": "Activity over time",
        "activity_by_volume": "Activity by volume",
        "recent_activity_timeline": "Recent Activity timeline",
        "new_post": "New Post",
        "by": "By",
        "context_body": "A summary of how people are interacting with {{site}}."
    },
    "form": {
        "add_field": "Add field",
        "add_field_instructions": "Add instructions to this field",
        "add_field_instructions_info":
            "These instructions will be presented as a tooltip to a user when filling in this field.",
        "add_field_instructions_placeholder": "Instructions for field",
        "add_post_type": "Add Survey",
        "add_step": "Add Task",
        "added_form_stage_attribute": "Saved field",
        "field_allowed_relation_post_type": "Allowed surveys",
        "choose_order_message":
            "Choose and order the fields that you would like to include in this form",
        "created_form_stage": "Saved task",
        "currently_disabled": "This survey is currently disabled.",
        "custom_structure": "Custom Structure",
        "default_date_placeholder": "Default date",
        "default_default_placeholder": "Default value",
        "default_location_placeholder": "Dar Es Salaam, Tanzania",
        "delete_post_type": "Delete this survey",
        "deleted_form": "Deleted Survey",
        "deleted_form_stage": "Deleted Task",
        "deleted_form_stage_attribute": "Deleted field",
        "editable_by": "Editable By",
        "edit_post_step": "Edit Task: {{step}}",
        "edit_post_type": "Edit Survey: {{form}}",
        "edit_post_types": "Edit Surveys",
        "field_add_option": "Add",
        "field_default": "Field Default",
        "field_key": "Field Key",
        "field_type": "Field Type",
        "field_name": "Field Name",
        "field_options": "Field Options",
        "field_key_placeholder": "field-key",
        "field_name_placeholder": "Title",
        "field_option_placeholder": "Option 1",
        "field_required": "This field is required",
        "form_name": "Name",
        "form_description": "Description",
        "forms": "Forms",
        "form_stage_settings_saved": "Saved settings",
        "permission_public": "Public",
        "permission_admin": "Admin",
        "permission_self": "Only Me",
        "post_type_settings": "Survey Settings",
        "post_step_settings": "Task Settings",
        "priority_changed": "Changed priority",
        "save_and_close": "Save & Close",
        "save_post_type": "Save Survey",
        "save_post_step": "Save Task",
        "saved_form": "Survey saved.",
        "step_delete": "Delete",
        "step_name": "Task Name",
        "step_rename": "Rename",
        "step_required": "This task is required",
        "post_step": "Post",
        "type": "type",
        "submit": "Submit",
        "visible_to": "Visible To"
    },

    "survey": {
        "title": "Title",
        "field_allowed_relation_survey": "Allowed surveys",
        "description": "Description",
        "task_description": "Task description",
        "post_fields": "Post fields",
        "task_fields": "Task fields",
        "show_task": "Show this task to everyone when the post is published",
        "task_info":
            "You can add additional information to each post, like translations or verification, and assign them to people by adding <strong>Tasks</strong>. You can choose from pre-built tasks or create your own from scratch.",
        "text_desc": "A sentence or less",
        "textarea_desc": "Multiple sentences or paragraphs<",
        "decimal_desc": "A number with decimal places",
        "integer_desc": "A whole number",
        "location_desc": "A geographic location chosen via a map",
        "date_desc": "A date with year, month, day",
        "datetime_desc": "A date with time in hours and minutes",
        "select_desc": "A drop down list of items",
        "radio_desc":
            "Allows the user to choose only one of a predefined set of options.",
        "checkbox_desc":
            "Allows the user to choose one or more of a predefined set of options.",
        "relation_desc": "Create a relation between different surveys",
        "upload_desc": "Allows image(s) to be uploaded to the post",
        "tasks": "Tasks",
        "add_task": "Add task",
        "add_field": "Add field",
        "edit_field": "Edit field",
        "task_name": "Task name",
        "require_field": "Require this field be completed",
        "name_this_task": "Name this task...",
        "require_review": "Require posts be reviewed before they're published",
        "require_task":
            "Require this task be completed before a post can be visible to the public",
        "survey_permissions": "Survey permissions",
        "who_can_add": "Who can add to this survey",
        "delete": {
            "desc":
                "<strong>If you delete this survey</strong>, all of its posts will also be deleted. Proceed with caution.",
            "delete": "Delete survey",
            "delete_this": "Delete this survey",
            "delete_this_field": "Delete this field",
            "delete_field": "Delete field",
            "desc_field":
                "<strong>If you delete this field</strong>, all of the data that was collected within it will also be deleted. Proceed with caution."
        },
        "name": "Survey name",
        "color": {
            "color": "Color",
            "hex": "Hex value",
            "red": "Red",
            "gold": "Gold",
            "blue": "Blue",
            "green": "Green"
        },
        "who": "Who can see this?",
        "task": {
            "delete": {
                "desc":
                    "<strong>If you delete this task</strong>, all of the data captured in it will also be deleted. Proceed with caution.",
                "delete": "Delete task",
                "delete_this": "Delete this task"
            }
        }
    },

    "global_filter": {
        "filter": "Filter",
        "categories": {
            "categories": "Incident Types",
            "all_categories": "All Incident Types"
        },
        "post_types": {
            "post_types": "Surveys",
            "all_types": "All Types",
            "unstructured": "Unstructured"
        },
        "post_statuses": {
            "post_statuses": "Status",
            "all_statuses": "Any Status",
            "draft": "Draft",
            "published": "Published"
        },
        "post_stages": {
            "post_stages": "Current Task",
            "all_stages": "All Tasks"
        },
        "collections": {
            "collections": "Collections",
            "all_collections": "All Collections",
            "search_collections": "Search Collections"
        },
        "filter_tabs": {
            "q": "Keyword",
            "date_range": "Date range",
            "created_after": "Start date",
            "created_before": "End date",
            "center_point": "Location",
            "tags": "Category",
            "form": "Survey",
            "current_stage": "Current Task",
            "set": "Collection",
            "status": "Status",
            "location_value": "Within {{km}} km of {{value}}",
            "visible_to": "Visible to",
            "user": "User"
        },
        "more": "More",
        "keyword": "Keyword",
        "start_date": "Start Date",
        "start_date_placeholder": "Jan 25, 2014",
        "end_date": "End Date",
        "end_date_placeholder": "April 25, 2014",
        "location": "Location",
        "location_placeholder": "Enter city or address",
        "within_km": "Within",
        "km": "Km",
        "within_km_placeholder": "Distance",
        "word_within": "Within",
        "option_1": "1 km",
        "option_2": "10 km",
        "option_3": "50 km",
        "option_4": "100 km",
        "option_5": "500 km",
        "clear_filters": "Clear Filters",
        "apply_filters": "Apply",
        "save_to_set": "Save to Set",
        "search": "Search",
        "date_range": "Date range",
        "who_its_visible_to": "Who it's visible to",
        "status": "Status"
    },
    "graph": {
        "all_posts": "Nothing; Show all posts",
        "all": "All time",
        "compare": "Compare...",
        "compare_all": "Compare...",
        "compare_status": "Compare status",
        "compare_tags": "Compare Incident Types",
        "compare_form": "Compare surveys",
        "compare_": "Compare ",
        "created": "Created time",
        "cumulative": "Show cumulative totals",
        "cumulative_post_count": "Total post count",
        "custom": "Custom date range",
        "custom_display": "{{start || 'Beginning'}} to {{end || 'Now'}}",
        "group_by_placeholder": "Graph",
        "new_post_count": "New posts",
        "none": "None",
        "no_data": "Loading data",
        "post_count": "Post count",
        "post_date": "Post created date",
        "select_one": "Select one",
        "week": "This week",
        "month": "This month",
        "updated": "Updated time"
    },
    "message": {
        "button": {
            "dismiss": "Dismiss",
            "default": "Ok",
            "cancel": "Cancel"
        }
    },
    "modal": {
        "button": {
            "close": "Close"
        }
    },
    "nav": {
        "about": "About",
        "activity": "Activity",
        "add_post": "Add Post",
        "account_settings": "Account Settings",
        "alerts": "Alerts",
        "all": "All",
        "author_tagline": "By {{author}}",
        "appearance": "Appearance",
        "back": "Back",
        "category": "Category",
        "categories": "Incident Types",
        "clear": "Clear",
        "collections": "Collections",
        "comments": "Comments",
        "data_sources": "Data Sources",
        "data_import": "Data Import",
        "deselect": "Deselect",
        "edit_profile": "Edit Profile",
        "edit_post_type": "Edit Survey",
        "everyone": "Everyone",
        "export": "Export",
        "forbidden": "Access Denied",
        "dashboard": "Reports",
        "personal_information": "Personal Information",
        "incidents": "Incidents",
        "my_incidents": "My Incidents",
        "assigned_incidents": "Assigned to me",
        "delete": "Delete",
        "edit": "Edit",
        "filters": "Filters",
        "forgotyourpassword": "Forgot your password?",
        "resetpassword": "Reset password",
        "resetmypassword": "Reset my password",
        "forms": "Forms",
        "help": "Help",
        "home": "Home",
        "login": "Log in",
        "logout": "Log out",
        "registration": "Sign up",
        "register": "Sign up",
        "map_settings": "Map Settings",
        "more": "More",
        "notifications": "Notifications",
        "only_author": "Only the author",
        "only_you": "Only you",
        "people": "People",
        "permissions": "Permissions",
        "plan_settings": "Plan",
        "please_wait_loading": "Please wait. Loading...",
        "plugins": "Plugins",
        "posts": "Posts",
        "posts_and_entities": "Surveys",
        "profile": "My Profile",
        "role": "Role",
        "roles": "Roles",
        "saved_searches": "Saved Searches",
        "search": "Search",
        "select_all": "Select all",
        "selected_items": "{{selectedItems}} selected",
        "settings": "Settings",
        "survey_settings": "Survey settings",
        "tags": "Incident Types",
        "today": "Today",
        "general": "General Settings",
        "type": "Type",
        "unknown": "Unknown",
        "unselect_all": "Unselect All",
        "user": "User",
        "users": "Users",
        "all_users": "All Users",
        "unreviewed_users": "Unreviewed Users",
        "role": "Role",
        "roles": "Roles",
        "views": "Views",
        "more": "More",
        "yesterday": "Yesterday",
        "forms": {
            "Witness": "Witness Form",
            "Witness_description":
                "To be filled with a person who is a witness to an incidence",
            "Victim": "Victim Form",
            "Victim_description":
                "To be filled with a person who is a victim to an incidence",
            "MCT": "MCT Form",
            "MCT_description": "To be filled with MCT staff"
        },
        "categories_tags": {
            "Denial": "Denial Access to Information",
            "Threat": "Threat",
            "Murder": "Murder",
            "Harassment": "Harassment",
            "Arrest": "Arrest",
            "Kidnap": "Kidnap",
            "Assault": "Assault",
            "Battery": "Battery",
            "Banning": "Banning",
            "Violent": "Violent of forceful eviction",
            "Confiscation": "Confiscation of equipment",
            "Damage": "Damage of equipment",
            "Cyber": "Cyber Attack"
        }
    },
    "views": {
        "map": "Map",
        "list": "Timeline",
        "activity": "Activity"
    },
    "dashboard": {
        "select_year": "Filter by year",
        "select_to": "Filter to(Date)",
        "select_from": "Filter from(Date)",
        "select_region": "Filter by region",
        "select_category": "Filter by category",
        "aggregate": "Aggregate reports",
        "summary": "Summary Report"
    },
    "post": {
        "add_to_form": "Add to {{form}}",
        "assign_to": "Assign this post to:",
        "assign_description": "Assignment Descriptions:",
        "feedback_description": "Post Feedback",
        "posts": "Posts",
        "there_are_no_posts": "There are no posts",
        "in_this_deployment": " in this deployment, yet.",
        "by": "by",
        "task_completed": "Task completed",
        "everyone": "Everyone",
        "just_you": "Just you",
        "specific_roles": "Specific roles...",
        "can_see_this": "can see this",
        "add_post_type": "Add {{form_name}}",
        "edit_post_type": "Edit {{form_name}}",
        "messages": "Message Thread",
        "post": "Post",
        "message": "Message",
        "message_thread": "Message Thread",
        "all_posts": "All Posts",
        "no_search_results": "Your search didn't match any posts",
        "no_posts_yet": "There are no posts yet!",
        "add_first_post": "Add the first post",
        "create_post": "Create Post",
        "create": "Create",
        "change": "Change",
        "posted_by": "Posted by",
        "draft": "Under review",
        "archived": "Private",
        "edited_by": "Edited by",
        "export": "Export",
        "select_all": "Select All",
        "unselect_all": "Unselect All",
        "per_page": "{{count}} posts",
        "post_details": "Post Details",
        "post": "Post",
        "steps": "This post's tasks",
        "mark_complete": "Mark as Complete",
        "complete": "Complete",
        "type_details": "{{type}} Details",
        "edit_post": "Edit Post",
        "history": "History",
        "hide_form": "Hide {{form}}",
        "hide_unknown_form": "Hide Unknown",
        "publish": "Publish to...",
        "publish_for_you": "You",
        "publish_for_everyone": "Everyone",
        "published": "Published",
        "unpublished": "Unpublished",
        "published_by": "Published by",
        "can_see_this_post": "can see this post",
        "location": "Location",
        "more_info": "Please can you provide more info?",
        "more_info_importance":
            "Adding more info will enable us to get broad understanding of the problem. Otherwise you will only submit the filled information",
        "yes_more_info": "Yes, Why not.",
        "no_more_info": "No, Thank you",
        "location_details": {
            "regions": "Regions",
            "wards": "Wards",
            "districts": "Districts"
        },
        "media_details": {
            "distributors": "Distributors",
            "outlets": "Outlets"
        },
        "categories": "Incident Types",
        "status": "Status",
        "type": "Type",
        "view": "View",
        "step": "Task",
        "update": "Update",
        "options": "Options",
        "complete_draft": "Complete (Draft)",
        "complete_published": "Complete (Published)",
        "structure": "Structure",
        "source": "via {{source}}",
        "related_posts": "Related incidents",
        "visible_to_public": "This post is visible to the public",
        "visible_to_you": "This post is visible to just you and admins",
        "visible_to_roles": "This post is visible to {{roles}}",
        "show_only_form": "Show only {{form}}",
        "show_only_unknown_form": "Show only Unknown",
        "delete_post": "Delete post",
        "delete_this_post": "Delete this post",
        "delete_post_desc":
            "<strong>If you delete this post</strong>, all of its comments will also be deleted. Proceed with caution.",
        "post_actions": {
            "edit": "Edit",
            "delete": "Delete",
            "export": "Export",
            "new": "New",
            "in_processing": "In Processing",
            "not_verified": "Not Verified",
            "filed": "Filed",
            "archive": "Private",
            "publish": "Publish"
        },
        "created_at": "<strong>Created</strong> at",
        "updated_at": "<strong>Last updated</strong> at",
        "author": "Author",
        "post_date": "Post date",
        "change_author": "Change author",
        "status_published": "Published Incident",
        "status_review": "New incident",
        "status_archived": "Private Incident",
        "status_in_processing": "In processing",
        "status_filed": "This post has been filed",
        "status_not_verified": "This post is not verified",
        "modify": {
            "intro": "Select survey:",
            "no_task_values":
                "There are currently no saved values for this task.",
            "create_type": "Create {{type}}",
            "edit_type": "Edit {{type}}: {{title}}",
            "add_a_post": "Add a post",
            "add_to_survey": "Add to this survey",
            "edit_post": "Edit {{title}}",
            "change_type": "Change type",
            "incomplete_step":
                "To publish a post all required tasks must be marked as complete. The required task {{stage}} has not yet been marked complete",
            "save_post": "Save Post",
            "publish": "Publish to...",
            "publish_to": "Publish to {{role}}",
            "published_to": "Published to {{role}}",
            "unpublish": "Revert to Draft",
            "everyone": "Everyone",
            "back": "Back",
            "contact_info": "Enter your contact info (optional)",
            "author_realname": "Name",
            "author_email": "Email",
            "form": {
                "categories": "Incident Types",
                "description": "Description",
                "location": "Location",
                "title": "Title",
                "link": {
                    "name": "Link Name",
                    "url": "Link url",
                    "add_link": "Add Link"
                }
            }
        },
        "preview": {
            "private": "private",
            "public": "public",
            "read_more": "&nbsp;... Read&nbsp;More",
            "tags": "Categories",
            "post_actions": {
                "post_actions": "Post Actions",
                "select": "select",
                "edit": "edit",
                "set": "set",
                "publish": "publish",
                "unpublish": "unpublish",
                "delete": "delete"
            }
        },
        "messages": {
            "by_sms": "By SMS",
            "by_email": "By Email",
            "by_twitter": "By Twitter",
            "reply": "Reply to Message thread",
            "send": "Send New Message",
            "save": "Save",
            "title": "Conversation with author",
            "associated_messages": "View messages associated with this post"
        },
        "valid": {
            "validation_fail":
                "Required fields are missing, these fields are marked with an * below.",
            "title": {
                "required": "A title is required",
                "minlength": "Title is too short",
                "maxlength": "Title is too long"
            },
            "content": {
                "required": "Description cannot be empty"
            },
            "tags": {
                "required": "Incident Types are required"
            },
            "values": {
                "required": "\"{{label}}\" is required",
                "parse": "\"{{label}}\" could not be parsed",
                "date": "\"{{label}}\" must be a date",
                "number": "\"{{label}}\" must be a number"
            },
            "author_name": {
                "maxlength": "Name is too long"
            },
            "author_email": {
                "email": "Invalid email"
            }
        },
        "media": {
            "current_url": "Current image",
            "add_caption": "Add caption",
            "upload": "Upload"
        },
        "unstructured": {
            "add_survey": {
                "info":
                    "<strong>This post hasn't yet been assigned to a survey within your deployment.</strong> That's because it was submitted via {{source}}, which doesn't provide a way for the author to add their post to a specific data point.",
                "choose": "Which survey would you like to add this post to?",
                "title": "Add this post to a survey",
                "save_and_edit": "Add to survey &amp; edit"
            },
            "survey_title": "Unknown Survey"
        }
    },
    "role": {
        "add_role": "Add Role",
        "edit_role": "Edit Role",
        "name": "Name",
        "description": "Description",
        "please_wait_loading": "Please wait. Loading roles...",
        "save": "Save Role",
        "save_add_another": "Save & Add Another",
        "permissions": "Permissions",
        "delete": "Delete",
        "delete_role": "Delete this role",
        "delete_role_desc":
            "<strong>If you delete this role</strong>, all of the users assigned to it will be assigned default permissions. Proceed with caution.",
        "everyone": "Everyone",
        "just_you": "Just you"
    },
    "saved_search": {
        "visible_to_public": "This Saved Search is visible to the public",
        "visible_to_you": "This Saved Search is visible to just you and admins",
        "visible_to_roles": "This Saved Search is visible to {{roles}}",
        "featured": "Featured",
        "featured_tooltip":
            "Featured searches appear to all users in <br>the \"Saved Searches\" menu"
    },
    "collection": {
        "visible_to_public": "This Collection is visible to the public",
        "visible_to_you": "This Collection is visible to just you and admins",
        "visible_to_roles": "This Collection is visible to {{roles}}"
    },
    "set": {
        "by": "by",
        "clear_search": "Clear Search",
        "create_collection": "Create Collection",
        "create_new": " Create New",
        "create_savedsearch": "Create Saved Search",
        "description": "Description",
        "delete_collection": "Delete this collection",
        "delete_savedsearch": "Delete this Saved Search",
        "default_viewing_mode": "Default viewing mode",
        "edit_collection_settings": "Edit Collection",
        "edit_search_settings": "Edit Saved Search",
        "featured": "Featured",
        "featured_collection": "This collection is featured",
        "featured_collection_tooltip":
            "Featured Collections appear to all users in <br>the \"Collections\" menu",
        "find_a_collection": "Find a collection...",
        "find_a_saved_search": "Find a saved search...",
        "add_to_collection": "Add to Collection",
        "empty_list_collections": "No collections",
        "empty_list_savedsearches": "No saved searches",
        "collection_name": "Collection name",
        "name_your_collection": "Name your collection...",
        "name_savedsearch": "Saved Search name",
        "visible_to_collection": "Who can see this Collection",
        "visible_to_savedsearch": "Who can see this Saved Search",
        "reset_savedsearch": "Reset Search",
        "save_set": "Save Collection",
        "save_savedsearch": "Save Search",
        "update_savedsearch": "Update Search",
        "set_settings": "Collection Settings",
        "add_notification": "Add Notification",
        "remove": "remove"
    },
    "category": {
        "add_tag": "Add Category",
        "save_add_another": "Save & Add Another",
        "delete_category": "Delete category",
        "delete_this_category": "Delete this category",
        "delete_category_desc":
            "<strong>If you delete this category</strong>, it will no longer be asociated with any posts. Proceed with caution.",
        "select_all": "Select All",
        "choose_roles": "Choose Roles",
        "create_tag": "Create Category",
        "saved_tag": "Category saved.",
        "deleted_tags": "Incident Types deleted.",
        "manage_tags": "Manage Incident Types",
        "edit_tag": "Edit Category",
        "editor": {
            "name": "Category Name",
            "slug": "Category Link",
            "slug_help":
                "If left blank, will be automatically generated from category name.",
            "description": "Description",
            "type": "Type",
            "color": "Icon Color",
            "icon": "Icon Name",
            "roles_help":
                "Only selected roles will be able to view this category."
        },
        "types": {
            "category": "Category",
            "status": "Status"
        },
        "delete": "Delete"
    },
    "tool": {
        "manage_forms": "Manage Forms",
        "manage_tags": "Manage Incident Types",
        "manage_users": "Manage Users",
        "manage_roles": "Manage Roles",
        "manage_views": "Manage Views",
        "manage_map_settings": "Manage Map Settings",
        "manage_appearance_settings": "Manage Appearance Settings",
        "manage_plugins": "Manage Plugins",
        "appearance_settings": "Appearance Settings",
        "map_settings": "Map Settings",
        "site_settings": "Site Settings",
        "settings": "Settings",
        "tools": "Tools"
    },
    "map_settings": {
        "admin_map_settings": "Map Settings",
        "saved_map_settings": "Map Settings saved."
    },
    "settings": {
        "appearance": "Appearance Settings",
        "continue": "Continue",
        "current_header": "Current background image",
        "customize_your_new_deployment": "Customize your new deployment",
        "settings_list": {
            "general": "General",
            "general_desc":
                "Change your deployment's name, description, logo, and other details.",
            "surveys": "Incident Forms",
            "surveys_desc":
                "Create and configure the forms your deployment collects.",
            "data_sources": "Data Sources",
            "data_sources_desc":
                "Configure email, SMS, and social media channels that help your deployment collect incident data.",
            "import": "Import",
            "import_desc":
                "Upload Incident data to your deployment from a CSV file.",
            "export": "Export",
            "export_desc":
                "Download Incident data from your deployment as a CSV file",
            "users": "Users",
            "users_desc":
                "Create and manage the people that contribute to your deployment.",
            "roles": "Roles",
            "roles_desc":
                "Create and manage the type of permissions your users have on your deployment.",
            "categories": "Incident Types",
            "categories_desc":
                "Create and manage the Incident Types people can put posts into for improved organization.",
            "plans": "Plans",
            "plan_desc":
                "Your deployment's current plan is <strong>{{current_plan}}</strong>.",
            "distributors": "Media distributors",
            "distributor_desc": "create and manage media house and outlets"
        },
        "data_sources": {
            "data_sources": "Data Sources",
            "enable_provider_message":
                "Accept incident submissions from this source",
            "hint_turn_on_off":
                "You can configure options for data sources, and then turn sources on and off.",
            "hint_plugin_needs_configuring":
                "You need to configure this data source before you can enable it.",
            "provider_options": "Options",
            "configure": "Configure",
            "options": "Options",
            "saving_changes": "Saving",
            "unavailable":
                "The datasource {{value}} is unavailable. <a href=\"/settings/plan\">Upgrade your plan</a> to get it!"
        },
        "deployment_delete_this": "Delete this deployment",
        "deployment_delete": "Delete deployment",
        "deployment_delete_info":
            "<strong>If you delete this deployment</strong>, all of its data will also be deleted. Proceed with caution.",
        "deployment_name": "Deployment name",
        "deployment_logo": "Deployment logo",
        "map_default_baselayer": "Default base layer",
        "map_clustering": "Combine nearby posts",
        "map_default_latitude": "Default latitude",
        "map_default_longitude": "Default longitude",
        "map_default_zoom_level": "Default zoom level",
        "map_default_location": "Default location",
        "site": "Site Settings",
        "site_email": "Contact email address",
        "site_email_note":
            "Use caution, as this will be visible to anyone on your site.",
        "site_description": "Describe your site",
        "site_description_placeholder": "An optional one sentence description",
        "site_timezone": "Site timezone",
        "site_language": "Site language",
        "site_sms_template_user": "SMS submission template",
        "site_sms_template_user_note":
            "This is the default template to be sent when user send an incident",
        "site_sms_template_mct": "SMS received template",
        "site_sms_template_mct_note":
            "This is the default template to be sent to MCT when incident is received",
        "site_welcome_sms_template": "Welcome SMS template",
        "site_welcome_sms_template_note":
            "This is the default template to be sent to new registed users",
        "site_form_default_sms": "Form to receive SMS",
        "site_form_default_sms_note": "This is the default form to receive sms",
        "site_role_default_sms": "Role to receive SMS",
        "site_role_default_sms_note": "This is the default role to receive sms",
        "site_private": "Make this deployment private",
        "password_recovery": "Password recovery message template",
        "site_sms_form": "Default form for sms received",
        "site_sms_form_note": "Any SMS sent will be received under this form",
        "site_sms_receive_user": "Default user role to receive sms",
        "site_sms_receive_user_note":
            "All user with this role will receive sms whenever incident is received through sms",
        "site_sms_enable_autoreply": "Enable auto-reply for received SMS",
        "site_sms_enable_autoreply_note":
            "If this is set to true auto-reply SMS will be sent",
        "site_sms_witness_form_field":
            "Field under witness form that will be used as a phone number",
        "site_sms_witness_form_field_note":
            "This field will hold witness Phone number for received SMS",
        "site_sms_victim_form_field":
            "Field under victim form that will be used as a phone number",
        "site_sms_victim_form_field_note":
            "This field will hold victim(reporter) Phone number for received SMS",
        "site_sms_blacklist": "List of number to be blacklisted",
        "site_sms_blacklist_note":
            "This list should be comma separated eg Vodacom,MPESA,CHEKA",
        "select_image": "Select image",
        "saved_settings": "Deployment settings saved!",
        "saved_map_settings": "Map settings saved!",
        "features": {
            "features": "Features",
            "please_select_features":
                "Please select the features you would like to enable."
        },
        "generic_field_error": "Invalid entry, please try again.",
        "skip": "Skip this task",
        "add_first_post": "Add Your First Post",
        "add_first_post_subtitle":
            "A deployment is nothing until it has some content in it.",
        "invite_people": "Or invite people to help you create posts.",
        "add_new_users": "Add New Users",
        "valid": {
            "name": {
                "required": "Site name is required"
            }
        }
    },
    "data_import": {
        "see_imported_posts": "See imported posts",
        "import_another_csv": "Import another CSV file",
        "import_complete": "Your import is complete",
        "complete_info":
            "The data from your CSV spreadsheet, <em>{{filename}}</em>, was successfully imported into your <a href=\"/settings/survey/{{form_id}}\">{{form_name}}</a> survey.",
        "import_another": "Import another CSV file",
        "nearly_complete": "Your import is nearly complete",
        "finish_info":
            "The data from your CSV spreadsheet is still being processed. You're welcome to leave this page or wait until the process is complete. Either way, we'll notify you when your data is ready.",
        "next": "Next",
        "configure_posts": "Configure Posts",
        "configure_explanation":
            "Through this interface you can set values for the data input fields that were not mapped to entries in the CSV. These values will be the same for all imported posts.",
        "import": "Import",
        "import_explanation_csv":
            "Import the data from a CSV spreadsheet into your deployment.",
        "import_explanation_file_size":
            "The size for a single file is limited to 10 MB",
        "import_explanation_format_location_1":
            "Geographic locations should be included as separate latitude and longitude columns in your CSV",
        "import_explanation_required_fields":
            "All required data fields must have an associated value set in the CSV. For example, if the field title is required then the field must be mapped to a CSV column where all rows of that column have a value set",
        "configure_posts": "Configure Posts",
        "configure_all_imported_posts": "Configure all imported posts",
        "csv": "CSV",
        "post_type": "Import to which data set?",
        "file_csv": "CSV file",
        "import_csv": "Import CSV",
        "choose_file": "Choose file",
        "organize_data": "Organize data",
        "organize_your_imported_data": "Assign CSV columns to post fields",
        "assign_csv_message": "Assign CSV columns to post fields",
        "csv_instructions":
            "Each of the column names from your CSV file are listed below. Choose the <strong>{{form_name}}</strong> field that you'd like each column to be imported to.",
        "csv_instructions_required_fields":
            "Fields marked with a [*] are required and must be assigned a value.",
        "csv_column_name": "CSV column name",
        "post_type_title": "{{form_name}} field name",
        "dont_import": "Don't import",
        "cancel_import": "Cancel import",
        "finish_import": "Finish import",
        "select_post_type": "Select Destination"
    },
    "data_export": {
        "csv": "CSV",
        "post_type": "Import to which data set?",
        "file_csv": "CSV file",
        "import_csv": "Import CSV",
        "choose_file": "Choose file",
        "resource_type": "Export which data set?",
        "export_explanation_csv":
            "Export data in CSV spreadsheet from  your deployment.",
        "export_csv": "Export CSV"
    },
    "user": {
        "add_user": "Add User",
        "edit_user": "Edit User",
        "activate_user": "Activate User",
        "update_password": "Update Password",
        "change_role": "Change Role",
        "save": "Save User",
        "gender": "Gender",
        "please_wait_loading": "Please wait. Loading users...",
        "save_add_another": "Save & Add Another",
        "show_all_roles": "Show All Roles",
        "cannot_delete_yourself": "You cannot delete your own user",
        "cannot_change_your_own_role": "You cannot change your own role",
        "confirm_password": "Confirm Password",
        "delete_user": "Delete user",
        "delete_this_user": "Delete this user",
        "delete_user_desc":
            "<strong>If you delete this user</strong>, posts created by this user will no longer have author information. This cannot be undone. Proceed with caution.",
        "edit_profile": "Edit Profile",
        "save_profile": "Save Profile",
        "cancel": "Cancel",
        "email": "Email Address/Phone Number/User Name",
        "email_only": "Email Address",
        "phoneNumber": "Phone Number",
        "failed_attempts": "Failed attempts: ",
        "full_name": "Display Name",
        "never": "Never",
        "no_full_name": "No display name",
        "number_of_users_shown": "Number of users shown",
        "per_page": "{{count}} users",
        "password": "Password",
        "new_password": "New Password",
        "register": "Register",
        "role": "Role: ",
        "roles": "Roles",
        "media_house": "Media House",
        "media_outlet": "Media Outlet",
        "saved_user": "User saved",
        "deleted_user": "User deleted",
        "search_and_filter": "Search & Filter Users",
        "passwordreset_instructions":
            "Enter your email to request a password reset",
        "passwordreset_confirm_instructions":
            "If your exists in our database, you will receive a password recovery sms soon with a token.",
        "token": "Password reset token",
        "valid": {
            "full_name": {
                "required": "Display name is required",
                "maxlength": "Display name is too long"
            },
            "email": {
                "required": "Email address is required",
                "email": "This email address is invalid",
                "maxlength": "This email address is too long"
            },
            "phoneNumber": {
                "required": "Phone number is required",
                "email": "This phone number is invalid",
                "maxlength": "This phone number is too long"
            },
            "password": {
                "required": "Password is required",
                "minlength": "This password is too short",
                "maxlength": "This password is too long"
            }
        }
    },
    "user_profile": {
        "title": "account settings",
        "update_password": "Update password",
        "update_success":
            "Your profile was updated successfully. You may need to sign out and back in before all your changes are reflected.",
        "button": {
            "save": "Save &amp; close",
            "cancel": "Cancel"
        },
        "nav": {
            "general": "General",
            "notifications": "Notifications"
        }
    },
    "user_create": {
        "full_name": "Display Name",
        "email": "Email Address",
        "role": "Role"
    },
    "notify": {
        "contact": {
            "save_success": "Contact saved",
            "delete_confirm": "Do you want to remove your account?",
            "error_message":
                "An error occurred while trying to remove your account",
            "destroy_success": "Account removed successfully"
        },
        "default": {
            "save_success": "Saved resource",
            "save_error": "Unable to save resource, please try again",
            "destroy_confirm": "Are you sure you want to delete this resource?",
            "destroy_success": "Deleted resource",
            "destroy_error": "Unable to delete resource, please try again",
            "destroy_cancelled": "Delete cancelled",
            "bulk_destroy_confirm":
                "Are you sure you want to delete {{count}} resources?",
            "bulk_destroy_success": "Deleted resource",
            "bulk_destroy_error": "Unable to delete resource, please try again",
            "bulk_destroy_cancelled": "Delete cancelled"
        },
        "data_import": {
            "see_imported_posts": "See imported posts",
            "csv_upload": "File {{name}} successfully uploaded",
            "csv_import_processed_errors":
                "<strong>Your CSV import</strong> is complete. {{processed}} records imported, {{errors}} records failed.",
            "csv_import_success_info":
                "The data from your CSV spreadsheet, <em>{{filename}}</em>, was successfully imported into your <a href=\"/views/list\">{{form_name}}</a> data.",
            "duplicate_fields":
                "Columns must be mapped to unique data fields. The following fields have more than 1 mapping: {{duplicates}}",
            "required_fields":
                "Required fields must be set to a value. The following fields have not been set: {{required}}",
            "no_mappings":
                "No fields have been assigned mappings - at least one field must be mapped to continue",
            "csv_import_cancel": "CSV import cancelled"
        },
        "general_settings": {
            "save_success": "General settings saved"
        },
        "map_settings": {
            "save_success": "Map settings saved"
        },
        "generic": {
            "okay": "Ok",
            "alerts": "Alerts",
            "confirm": "Confirmation"
        },
        "login": {
            "failed": "Login failed, check your email and password."
        },
        "register": {
            "failed":
                "There were errors during registration. Check your input and try again.",
            "success": "Registration complete! You can now log in.."
        },
        "post": {
            "save_success": "Post {{name}} saved",
            "save_success_review":
                "Post {{name}} saved. Your post will be reviewed by a moderator before publishing.",
            "save_error": "Unable to save post, please try again",
            "publish_success": "Post has been published for {{role}}",
            "set_draft": "Post is now visible to just you",
            "publish_error": "Unable to publish post, please try again",
            "unpublish_success": "Post has been unpublished",
            "unpublish_error": "Unable to unpublish post, please try again",
            "yes_confirm_delete": "Yes, delete this post",
            "confirm_delete": "Confirm Delete",
            "destroy_title": "Delete Post",
            "delete_question":
                "Are you sure you want to delete your post {{post}}?",
            "destroy_success": "Post {{name}} deleted",
            "destroy_success_bulk": "Posts deleted",
            "destroy_confirm": "Are you sure you want to delete this post?",
            "destroy_success": "Post deleted",
            "destroy_error": "Unable to delete post, please try again",
            "bulk_destroy_confirm":
                "Are you sure you want to delete {{count}} posts?",
            "stage_save_success": "Updated {{stage}}",
            "export":
                "This will export filtered posts. Are you sure you want to continue?",
            "update_status_success_bulk": "Status updated for {{count}} posts"
        },
        "category": {
            "save_success": "Saved category {{name}}",
            "destroy_confirm": "Are you sure you want to delete this category?",
            "destroy_success": "Category deleted",
            "destroy_error": "Unable to delete category, please try again",
            "bulk_destroy_confirm":
                "Are you sure you want to delete {{count}} Incident Types?",
            "bulk_destroy_success": "{{count}} Incident Types deleted"
        },
        "role": {
            "delete_question":
                "Are you sure you want to delete the role {{role}}?",
            "destroy_success": "Role {{role}} deleted.",
            "save_success": "Role {{role}} saved.",
            "last_admin": "You can not delete the only admin role"
        },
        "datasource": {
            "save_success": "Saved Data Source {{name}}"
        },
        "user": {
            "save_success": "User {{name}} saved",
            "request_review": "User {{name}} is saved waiting for review",
            "edit_success": "User {{name}} updated",
            "destroy_confirm": "Are you sure you want to delete this user?",
            "destroy_success": "User deleted",
            "destroy_error": "Unable to delete user, please try again",
            "bulk_destroy_confirm":
                "Are you sure you want to delete {{count}} users?",
            "bulk_destroy_success": "Users deleted",
            "bulk_role_change_confirm":
                "Are you sure you want to change the role of {{count}} users to {{role}}?",
            "bulk_role_change_success": "User roles changed to {{role_name}}"
        },
        "form": {
            "save_success": "Survey {{name}} saved",
            "save_stage_success": "Survey task {{name}} saved",
            "save_attribute_success": "Field {{name}} added",
            "edit_form_success": "Survey {{name}} updated",
            "edit_stage_success": "Survey task {{name}} updated",
            "delete_form_confirm":
                "Are you sure you want to delete this survey?",
            "destroy_form_success": "Survey {{name}} deleted",
            "delete_stage_confirm":
                "Are you sure you want to delete this task?",
            "destroy_stage_success": "Survey task {{name}} deleted",
            "delete_attribute_confirm":
                "Are you sure you want to delete this field?",
            "destroy_attribute_success": "Field {{name}} deleted"
        },
        "collection": {
            "delete_collection_confirm":
                "Are you sure you want to delete this collection?",
            "created_collection":
                "Collection {{collection}} created successfully",
            "add_to_collection": "Post has been added to {{collection}}",
            "removed_from_collection":
                "Post has been removed from {{collection}}",
            "bulk_add_to_collection":
                "{{count}} posts have been added to {{collection}}"
        },
        "savedsearch": {
            "delete_savedsearch_confirm":
                "Are you sure you want to delete this saved search?"
        },
        "passwordreset": {
            "failed": "Password reset failed",
            "success": "Your password has been reset"
        },
        "notification": {
            "add":
                "You will now be notified when new posts are added to {{set}}",
            "delete_confirm":
                "Are you sure you want to remove this notification?",
            "destroy_success":
                "<strong>You will no longer receive notifications about</strong> {{name}}."
        },
        "message": {
            "sent_to": "Message sent to {{contact}}"
        },
        "resource": {
            "export":
                "This will export resources from database. Are you sure you want to continue?"
        }
    },
    "empty": {
        "default": "No records found.",
        "post": "No posts found.",
        "user": "No users found.",
        "category": "No Incident Types found.",
        "role": "No roles found.",
        "permission": "No permissions found.",
        "form": "No forms found."
    },
    "location": {
        "search": "Search",
        "save": "Save Location",
        "get_current_position": "Get current position",
        "update_location": "Update location",
        "click_map": "Click the map to place a marker",
        "clear": "Clear"
    },
    "notification": {
        "title": "Notifications",
        "contacts_heading": "How you get notifications",
        "notifications_heading": "What you get notified about",
        "error_message": "The notification could not be deleted at this time",
        "send_to_address": "Send notifications to this address",
        "button": {
            "delete": "Delete notification"
        }
    },
    "contact": {
        "edit": "Edit",
        "phone_number": "Phone Number",
        "home_address": "Home Adress",
        "save_changes": "Save changes",
        "saving_changes": "Saving changes",
        "cancel_edit": "Cancel",
        "add_phone_number": "Add a phone number",
        "add_email_address": "Add an email address",
        "edit_phone_number": "Edit your phone number",
        "edit_email_address": "Edit your email address",
        "error_message": "The contact could not be saved at this time",
        "type": {
            "phone": "Phone",
            "email": "Email"
        },
        "button": {
            "add": "Add account",
            "delete": "Remove account"
        },
        "valid": {
            "email": {
                "required":
                    "An email address is required to receive notifications",
                "email": "This email address is invalid",
                "maxlength": "This email address is too long"
            },
            "phone": {
                "required":
                    "A phone number is required to receive notifications",
                "pattern": "A valid phone number is required",
                "minlength": "This number is too short",
                "maxlength": "This number is too long"
            }
        }
    },
    "languages": {
        "ach": "Acoli",
        "ady": "Adyghe",
        "af": "Afrikaans",
        "af-ZA": "Afrikaans (South Africa)",
        "ak": "Akan",
        "sq": "Albanian",
        "sq-AL": "Albanian (Albania)",
        "aln": "Albanian Gheg",
        "am": "Amharic",
        "am-ET": "Amharic (Ethiopia)",
        "ar": "Arabic",
        "ar-EG": "Arabic (Egypt)",
        "ar-SA": "Arabic (Saudi Arabia)",
        "ar-SD": "Arabic (Sudan)",
        "ar-SY": "Arabic (Syria)",
        "ar-AA": "Arabic (Unitag)",
        "an": "Aragonese",
        "hy": "Armenian",
        "hy-AM": "Armenian (Armenia)",
        "as": "Assamese",
        "as-IN": "Assamese (India)",
        "ast": "Asturian",
        "ast-ES": "Asturian (Spain)",
        "az": "Azerbaijani",
        "az@Arab": "Azerbaijani (Arabic)",
        "az-AZ": "Azerbaijani (Azerbaijan)",
        "az-IR": "Azerbaijani (Iran)",
        "az@latin": "Azerbaijani (Latin)",
        "bal": "Balochi",
        "ba": "Bashkir",
        "eu": "Basque",
        "eu-ES": "Basque (Spain)",
        "bar": "Bavarian",
        "be": "Belarusian",
        "be-BY": "Belarusian (Belarus)",
        "be@tarask": "Belarusian (Tarask)",
        "bn": "Bengali",
        "bn-BD": "Bengali (Bangladesh)",
        "bn-IN": "Bengali (India)",
        "brx": "Bodo",
        "bs": "Bosnian",
        "bs-BA": "Bosnian (Bosnia and Herzegovina)",
        "br": "Breton",
        "bg": "Bulgarian",
        "bg-BG": "Bulgarian (Bulgaria)",
        "my": "Burmese",
        "my-MM": "Burmese (Myanmar)",
        "ca": "Catalan",
        "ca-ES": "Catalan (Spain)",
        "ca@valencia": "Catalan (Valencian)",
        "ceb": "Cebuano",
        "tzm": "Central Atlas Tamazight",
        "hne": "Chhattisgarhi",
        "cgg": "Chiga",
        "zh": "Chinese",
        "zh-CN": "Chinese (China)",
        "zh-CN.GB2312": "Chinese (China) (GB2312)",
        "gan": "Chinese (Gan)",
        "hak": "Chinese (Hakka)",
        "zh-HK": "Chinese (Hong Kong)",
        "czh": "Chinese (Huizhou)",
        "cjy": "Chinese (Jinyu)",
        "lzh": "Chinese (Literary)",
        "cmn": "Chinese (Mandarin)",
        "mnp": "Chinese (Min Bei)",
        "cdo": "Chinese (Min Dong)",
        "nan": "Chinese (Min Nan)",
        "czo": "Chinese (Min Zhong)",
        "cpx": "Chinese (Pu-Xian)",
        "zh-Hans": "Chinese Simplified",
        "zh-TW": "Chinese (Taiwan)",
        "zh-TW.Big5": "Chinese (Taiwan) (Big5) ",
        "zh-Hant": "Chinese Traditional",
        "wuu": "Chinese (Wu)",
        "hsn": "Chinese (Xiang)",
        "yue": "Chinese (Yue)",
        "cv": "Chuvash",
        "ksh": "Colognian",
        "kw": "Cornish",
        "co": "Corsican",
        "crh": "Crimean Turkish",
        "hr": "Croatian",
        "hr-HR": "Croatian (Croatia)",
        "cs": "Czech",
        "cs-CZ": "Czech (Czech Republic)",
        "da": "Danish",
        "da-DK": "Danish (Denmark)",
        "dv": "Divehi",
        "doi": "Dogri",
        "nl": "Dutch",
        "nl-BE": "Dutch (Belgium)",
        "nl-NL": "Dutch (Netherlands)",
        "dz": "Dzongkha",
        "dz-BT": "Dzongkha (Bhutan)",
        "en": "English",
        "en-AU": "English (Australia)",
        "en-AT": "English (Austria)",
        "en-BD": "English (Bangladesh)",
        "en-BE": "English (Belgium)",
        "en-CA": "English (Canada)",
        "en-CL": "English (Chile)",
        "en-CZ": "English (Czech Republic)",
        "en-ee": "English (Estonia)",
        "en-FI": "English (Finland)",
        "en-DE": "English (Germany)",
        "en-GH": "English (Ghana)",
        "en-HK": "English (Hong Kong)",
        "en-HU": "English (Hungary)",
        "en-IN": "English (India)",
        "en-IE": "English (Ireland)",
        "en-lv": "English (Latvia)",
        "en-lt": "English (Lithuania)",
        "en-NL": "English (Netherlands)",
        "en-NZ": "English (New Zealand)",
        "en-NG": "English (Nigeria)",
        "en-PK": "English (Pakistan)",
        "en-PL": "English (Poland)",
        "en-RO": "English (Romania)",
        "en-SK": "English (Slovakia)",
        "en-ZA": "English (South Africa)",
        "en-LK": "English (Sri Lanka)",
        "en-SE": "English (Sweden)",
        "en-CH": "English (Switzerland)",
        "en-GB": "English (United Kingdom)",
        "en-US": "English (United States)",
        "myv": "Erzya",
        "eo": "Esperanto",
        "et": "Estonian",
        "et-EE": "Estonian (Estonia)",
        "fo": "Faroese",
        "fo-FO": "Faroese (Faroe Islands)",
        "fil": "Filipino",
        "fi": "Finnish",
        "fi-FI": "Finnish (Finland)",
        "frp": "Franco-Provençal (Arpitan)",
        "fr": "French",
        "fr-BE": "French (Belgium)",
        "fr-CA": "French (Canada)",
        "fr-FR": "French (France)",
        "fr-CH": "French (Switzerland)",
        "fur": "Friulian",
        "ff": "Fulah",
        "ff-SN": "Fulah (Senegal)",
        "gd": "Gaelic, Scottish",
        "gl": "Galician",
        "gl-ES": "Galician (Spain)",
        "lg": "Ganda",
        "ka": "Georgian",
        "ka-GE": "Georgian (Georgia)",
        "de": "German",
        "de-AT": "German (Austria)",
        "de-DE": "German (Germany)",
        "de-CH": "German (Switzerland)",
        "el": "Greek",
        "el-GR": "Greek (Greece)",
        "kl": "Greenlandic",
        "gu": "Gujarati",
        "gu-IN": "Gujarati (India)",
        "gun": "Gun",
        "ht": "Haitian (Haitian Creole)",
        "ht-HT": "Haitian (Haitian Creole) (Haiti)",
        "ha": "Hausa",
        "haw": "Hawaiian",
        "he": "Hebrew",
        "he-IL": "Hebrew (Israel)",
        "hi": "Hindi",
        "hi-IN": "Hindi (India)",
        "hu": "Hungarian",
        "hu-HU": "Hungarian (Hungary)",
        "is": "Icelandic",
        "is-IS": "Icelandic (Iceland)",
        "io": "Ido",
        "ig": "Igbo",
        "ilo": "Iloko",
        "id": "Indonesian",
        "id-ID": "Indonesian (Indonesia)",
        "ia": "Interlingua",
        "iu": "Inuktitut",
        "ga": "Irish",
        "ga-IE": "Irish (Ireland)",
        "it": "Italian",
        "it-IT": "Italian (Italy)",
        "it-CH": "Italian (Switzerland)",
        "ja": "Japanese",
        "ja-JP": "Japanese (Japan)",
        "jv": "Javanese",
        "kab": "Kabyle",
        "kn": "Kannada",
        "kn-IN": "Kannada (India)",
        "pam": "Kapampangan",
        "ks": "Kashmiri",
        "ks-IN": "Kashmiri (India)",
        "csb": "Kashubian",
        "kk": "Kazakh",
        "kk@Arab": "Kazakh (Arabic)",
        "kk@Cyrl": "Kazakh (Cyrillic)",
        "kk-KZ": "Kazakh (Kazakhstan)",
        "kk@latin": "Kazakh (Latin)",
        "km": "Khmer",
        "km-KH": "Khmer (Cambodia)",
        "rw": "Kinyarwanda",
        "ky": "Kirgyz",
        "tlh": "Klingon",
        "kok": "Konkani",
        "ko": "Korean",
        "ko-KR": "Korean (Korea)",
        "ku": "Kurdish",
        "ku-IQ": "Kurdish (Iraq)",
        "lad": "Ladino",
        "lo": "Lao",
        "lo-LA": "Lao (Laos)",
        "ltg": "Latgalian",
        "la": "Latin",
        "lv": "Latvian",
        "lv-LV": "Latvian (Latvia)",
        "lez": "Lezghian",
        "lij": "Ligurian",
        "li": "Limburgian",
        "ln": "Lingala",
        "lt": "Lithuanian",
        "lt-LT": "Lithuanian (Lithuania)",
        "jbo": "Lojban",
        "en@lolcat": "LOLCAT English",
        "lmo": "Lombard",
        "dsb": "Lower Sorbian",
        "nds": "Low German",
        "lb": "Luxembourgish",
        "mk": "Macedonian",
        "mk-MK": "Macedonian (Macedonia)",
        "mai": "Maithili",
        "mg": "Malagasy",
        "ms": "Malay",
        "ml": "Malayalam",
        "ml-IN": "Malayalam (India)",
        "ms-MY": "Malay (Malaysia)",
        "mt": "Maltese",
        "mt-MT": "Maltese (Malta)",
        "mni": "Manipuri",
        "mi": "Maori",
        "arn": "Mapudungun",
        "mr": "Marathi",
        "mr-IN": "Marathi (India)",
        "mh": "Marshallese",
        "mw1": "Mirandese",
        "mn": "Mongolian",
        "mn-MN": "Mongolian (Mongolia)",
        "nah": "Nahuatl",
        "nv": "Navajo",
        "nr": "Ndebele, South",
        "nap": "Neapolitan",
        "ne": "Nepali",
        "ne-NP": "Nepali (Nepal)",
        "nia": "Nias",
        "nqo": "N'ko",
        "se": "Northern Sami",
        "nso": "Northern Sotho",
        "no": "Norwegian",
        "nb": "Norwegian Bokmål",
        "nb-NO": "Norwegian Bokmål (Norway)",
        "no-NO": "Norwegian (Norway)",
        "nn": "Norwegian Nynorsk",
        "nn-NO": "Norwegian Nynorsk (Norway)",
        "ny": "Nyanja",
        "oc": "Occitan (post 1500)",
        "or": "Oriya",
        "or-IN": "Oriya (India)",
        "om": "Oromo",
        "os": "Ossetic",
        "pfl": "Palatinate German",
        "pa": "Panjabi (Punjabi)",
        "pa-IN": "Panjabi (Punjabi) (India)",
        "pap": "Papiamento",
        "fa": "Persian",
        "fa-AF": "Persian (Afghanistan)",
        "fa-IR": "Persian (Iran)",
        "pms": "Piemontese",
        "en@pirate": "Pirate English",
        "pl": "Polish",
        "pl-PL": "Polish (Poland)",
        "pt": "Portuguese",
        "pt-BR": "Portuguese (Brazil)",
        "pt-PT": "Portuguese (Portugal)",
        "ps": "Pushto",
        "ro": "Romanian",
        "ro-RO": "Romanian (Romania)",
        "rm": "Romansh",
        "ru": "Russian",
        "ru-ee": "Russian (Estonia)",
        "ru-lv": "Russian (Latvia)",
        "ru-lt": "Russian (Lithuania)",
        "ru@petr1708": "Russian Petrine orthography",
        "ru-RU": "Russian (Russia)",
        "sah": "Sakha (Yakut)",
        "sm": "Samoan",
        "sa": "Sanskrit",
        "sat": "Santali",
        "sc": "Sardinian",
        "sco": "Scots",
        "sr": "Serbian",
        "sr@Ijekavian": "Serbian (Ijekavian)",
        "sr@ijekavianlatin": "Serbian (Ijekavian Latin)",
        "sr@latin": "Serbian (Latin)",
        "sr-RS@latin": "Serbian (Latin) (Serbia)",
        "sr-RS": "Serbian (Serbia)",
        "sn": "Shona",
        "scn": "Sicilian",
        "szl": "Silesian",
        "sd": "Sindhi",
        "si": "Sinhala",
        "si-LK": "Sinhala (Sri Lanka)",
        "sk": "Slovak",
        "sk-SK": "Slovak (Slovakia)",
        "sl": "Slovenian",
        "sl-SI": "Slovenian (Slovenia)",
        "so": "Somali",
        "son": "Songhay",
        "st": "Sotho, Southern",
        "st-ZA": "Sotho, Southern (South Africa)",
        "sma": "Southern Sami",
        "es": "Spanish",
        "es-AR": "Spanish (Argentina)",
        "es-BO": "Spanish (Bolivia)",
        "es-CL": "Spanish (Chile)",
        "es-CO": "Spanish (Colombia)",
        "es-CR": "Spanish (Costa Rica)",
        "es-DO": "Spanish (Dominican Republic)",
        "es-EC": "Spanish (Ecuador)",
        "es-SV": "Spanish (El Salvador)",
        "es-GT": "Spanish (Guatemala)",
        "es-419": "Spanish (Latin America)",
        "es-MX": "Spanish (Mexico)",
        "es-NI": "Spanish (Nicaragua)",
        "es-PA": "Spanish (Panama)",
        "es-PY": "Spanish (Paraguay)",
        "es-PE": "Spanish (Peru)",
        "es-PR": "Spanish (Puerto Rico)",
        "es-ES": "Spanish (Spain)",
        "es-US": "Spanish (United States)",
        "es-UY": "Spanish (Uruguay)",
        "es-VE": "Spanish (Venezuela)",
        "su": "Sundanese",
        "sw": "Swahili",
        "sw-KE": "Swahili (Kenya)",
        "ss": "Swati",
        "sv": "Swedish",
        "sv-FI": "Swedish (Finland)",
        "sv-SE": "Swedish (Sweden)",
        "tl": "Tagalog",
        "tl-PH": "Tagalog (Philippines)",
        "tg": "Tajik",
        "tg-TJ": "Tajik (Tajikistan)",
        "tzl": "Talossan",
        "ta": "Tamil",
        "ta-IN": "Tamil (India)",
        "ta-LK": "Tamil (Sri-Lanka)",
        "tt": "Tatar",
        "te": "Telugu",
        "te-IN": "Telugu (India)",
        "tet": "Tetum (Tetun)",
        "th": "Thai",
        "th-TH": "Thai (Thailand)",
        "bo": "Tibetan",
        "bo-CN": "Tibetan (China)",
        "ti": "Tigrinya",
        "to": "Tongan",
        "ts": "Tsonga",
        "tn": "Tswana",
        "tr": "Turkish",
        "tr-TR": "Turkish (Turkey)",
        "tk": "Turkmen",
        "tk-TM": "Turkmen (Turkmenistan)",
        "udm": "Udmurt",
        "ug": "Uighur",
        "ug@Arab": "Uighur (Arabic)",
        "ug@Cyrl": "Uighur (Cyrillic)",
        "ug@Latin": "Uighur (Latin)",
        "uk": "Ukrainian",
        "uk-UA": "Ukrainian (Ukraine)",
        "vmf": "Upper Franconian",
        "hsb": "Upper Sorbian",
        "ur": "Urdu",
        "ur-PK": "Urdu (Pakistan)",
        "uz": "Uzbek",
        "uz@Arab": "Uzbek (Arabic)",
        "uz@Cyrl": "Uzbek (Cyrillic)",
        "uz@Latn": "Uzbek (Latin)",
        "uz-UZ": "Uzbek (Uzbekistan)",
        "ve": "Venda",
        "vec": "Venetian",
        "vi": "Vietnamese",
        "vi-VN": "Vietnamese (Viet Nam)",
        "vls": "Vlaams",
        "wa": "Walloon",
        "war": "Wáray-Wáray",
        "cy": "Welsh",
        "cy-GB": "Welsh (United Kingdom)",
        "fy": "Western Frisian",
        "fy-NL": "Western Frisian (Netherlands)",
        "wo": "Wolof",
        "wo-SN": "Wolof (Senegal)",
        "xh": "Xhosa",
        "yi": "Yiddish",
        "yo": "Yoruba",
        "zu": "Zulu",
        "zu-ZA": "Zulu (South Africa)"
    }
}
