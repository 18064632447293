module.exports = [
    '$resource',
    '$http',
    'Util',
    '_',
function (
    $resource,
    $http,
    Util,
    _
) {

    var ResourceExportEndpoint = $resource(Util.apiUrl('/resources/export'), {}, {
        query: {
            method: 'GET',
            isArray: true,
            transformResponse: function (data /*, header*/) {
                return angular.fromJson(data).results;
            }
        },
        get: {
            method: 'GET',
            params: {'ignore403': '@ignore403'}
        }
    });

    ResourceExportEndpoint.export = function (resource) {
        var config =  {
            params: resource,
            paramSerializer: '$httpParamSerializerJQLike'
        };

        return $http.get(Util.apiUrl('/resources/export'), config);

    }

    return ResourceExportEndpoint;
}];
