module.exports = PostViewsController;

PostViewsController.$inject = ['$scope', '$translate', '$routeParams', 'PostFilters'];
function PostViewsController($scope, $translate, $routeParams, PostFilters) {
    // Set view based out route
    $scope.currentView = $routeParams.view;
    $scope.cancelFilters = cancelFilters;
    $scope.searchFilters = searchFilters;
    $scope.areFilterCleared = false;
    $scope.showAddButton = true;

    if ($scope.currentView == 'list') {
        $scope.showAddButton = false;
    }else {
        $scope.showAddButton = true;
    }


    // Set the page title
    $translate('post.posts').then(function (title) {
        $scope.title = title;
        $scope.$emit('setPageTitle', title);
    });

    $scope.filters = PostFilters.getFilters();

    function cancelFilters() {
        $scope.filters = PostFilters.clearFilters();
        $scope.areFilterCleared = !$scope.areFilterCleared;
    }

    function searchFilters() {
        $scope.$broadcast('searchFilters');
    }

}
