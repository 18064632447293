module.exports = [
    '$routeProvider',
function (
    $routeProvider
) {

    $routeProvider
    .when('/dashboard', {
        controller: require('./dashboard.controller.js'),
        templateUrl: 'templates/main/dashboard/main.html'
    })
    .when('/dashboard/aggregate', {
        controller: require('./dashboard-aggregate.controller.js'),
        templateUrl: 'templates/main/dashboard/aggregate.html'
    });

}];
