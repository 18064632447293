module.exports = [
    '$resource',
    'Util',
    '_',
function (
    $resource,
    Util,
    _
) {

    var OutletEndpoint = $resource(Util.apiUrl('/outlets/:id'), {
        id: '@id'
    }, {
        query: {
            method: 'GET',
            isArray: true,
            transformResponse: function (data /*, header*/) {
                return angular.fromJson(data).results;
            }
        },
        get: {
            method: 'GET',
            params: {
                'ignore403': '@ignore403',
                'distributor_id': '@distributor_id'
            }
        },
        update: {
            method: 'PUT'
        }
    });

    return OutletEndpoint;
}];
