module.exports = ReportBarChart;

function ReportBarChart() {
    return {
        restrict: "E",
        scope: {
            filters: "=",
            change: "="
        },
        controller: ReportBarChartController,
        templateUrl: "templates/main/dashboard/bar-chart-report.html"
    };
}

ReportBarChartController.$inject = [
    "$scope",
    "$element",
    "$translate",
    "ReportEndpoint",
    "d3",
    "_"
];
function ReportBarChartController(
    $scope,
    $element,
    $translate,
    ReportEndpoint,
    d3,
    _
) {
    $scope.nvd3Id = "barChart" + $scope.filters.parameters.id;
    $scope.data = [
        {
            values: []
        }
    ];

    $scope.options = {
        chart: {
            type: "discreteBarChart",
            reduceXTicks: false,
            //staggerLabels:true,
            height: "450",
            width: $scope.filters.width,
            margin: {
                top: 20,
                right: 20,
                bottom: 40,
                left: 55
            },
            x: function(d) {
                return d[$scope.filters.xAxisValue];
            },
            y: function(d) {
                return d[$scope.filters.yAxisValue];
            },
            showValues: false,
            showControls: false,
            valueFormat: d3.format("d"),
            transitionDuration: 500,
            xAxis: {
                rotateLabels: -90,
                tickPadding: -20,
                height: 60,
                margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },
                // you can use this value to pass an array
                // with the numbers the x axis will have.
                //"tickValues": [0,5,15,30,60,90,99],
                tickSubdivide: 10,
                tickSize: 2
            },
            yAxis: {
                axisLabel: "total",
                tickFormat: d3.format("d"),
                axisLabelDistance: -10,
                showMaxMin: true,
                height: 60,
                ticks: null,
                width: 75,
                tickValues: null
            },
            tooltip: {
                contentGenerator: function(data) {
                    return (
                        "<h3>" +
                        data["data"][$scope.filters.xAxisValue] +
                        "</h3>" +
                        "<p>" +
                        data["data"][$scope.filters.yAxisValue] +
                        "</p>"
                    );
                }
            },
            forceX: 0,
            barColor: d3.scale.category20().range(),
            noData: $translate.instant("graph.no_data")
        }
    };

    activate();

    function activate() {
        // whenever the filters changes, update the report
        getDataStats();
        $scope.$on(
            "someEvent",
            function() {
                getDataStats();
            },
            true
        );
    }

    function getDataStats() {
        ReportEndpoint.get($scope.filters.parameters).$promise.then(function(
            response
        ) {
            $scope.dataMetadata = response;
            $scope.title = response.title;
            $scope.pngTitle = response.title
                .split(" ")
                .join("-")
                .toLowerCase();
            $scope.csvName =
                response.title
                    .split(" ")
                    .join("-")
                    .toLowerCase() + ".csv";
            $scope.data[0].values = response.data;
            var obtained_data = response.data;
            var max_value = Math.max.apply(
                Math,
                obtained_data.map(function(o) {
                    return o.count;
                })
            );
            $scope.options.chart.yDomain = [0, max_value + 2];
        });
    }
}
