module.exports = [
    '$routeProvider',
function (
    $routeProvider
) {

    $routeProvider
    .when('/user/profile', {
        controller: require('./profile/profile-user.controller.js'),
        templateUrl: 'templates/main/user/profile/main.html'
    })
    .when('/user/profile/edit', {
        controller: require('./profile/profile-user-edit.controller.js'),
        templateUrl: 'templates/main/user/profile/profile-user-edit.html'
    })
    .when('/user/profile/myincidents', {
        controller: require('./profile/my-incidents.controller.js'),
        templateUrl: 'templates/main/user/profile/my-incidents.html'
    })
    .when('/user/profile/incidents/assigned', {
        controller: require('./profile/assigned-incidents.controller.js'),
        templateUrl: 'templates/main/user/profile/assigned-incidents.html'
    });
}];
