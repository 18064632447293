angular.module('ushahidi.settings', [])
    .directive('afterImportCsv', require('./data-import/data-after-import.directive.js'))
    .directive('importerCsv', require('./data-import/data-import.directive.js'))
    .directive('mapperCsv', require('./data-import/data-mapper.directive.js'))
    .directive('configureCsv', require('./data-import/data-configure.directive.js'))
    .service('ImportNotify', require('./data-import/import.notify.service.js'))
    .service('DataRetriever', require('./data-import/data-retriever.service.js'))

.directive('surveyEditor', require('./surveys/survey-editor.directive.js'))
    .directive('surveyTaskCreate', require('./surveys/task-create.directive.js'))
    .directive('surveyAttributeCreate', require('./surveys/attribute-create.directive.js'))
    .directive('surveyAttributeEditor', require('./surveys/attribute-editor.directive.js'))

.directive('settingsList', require('./settings-list.directive.js'))
    .directive('settingsMap', require('./site/map.directive.js'))
    .directive('settingsEditor', require('./site/editor.directive.js'))

.directive('filterUsers', require('./users/filter-users.directive.js'))

.directive('customRoles', require('./roles/roles.directive.js'))
    .directive('customRolesEditor', require('./roles/editor.directive.js'))
    .constant('RESOURCES', [{
        id: 'statuses',
        name: 'Statuses'
    }, {
        id: 'regions',
        name: 'Regions'
    }, {
        id: 'districts',
        name: 'Districts'
    }, {
        id: 'wards',
        name: 'Wards'
    }, {
        id: 'distributors',
        name: 'Distributors'
    }, {
        id: 'outlets',
        name: 'Outlets'
    }])
    .config(require('./settings.routes.js'));
