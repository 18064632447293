module.exports = PostCardDirective;

PostCardDirective.$inject = ['FormEndpoint', 'FormAttributeEndpoint'];
function PostCardDirective(FormEndpoint, FormAttributeEndpoint) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            post:  '=',
            canSelect: '=',
            selectedPosts: '=',
            shortContent: '@'
        },
        templateUrl: 'templates/main/posts/views/card.html',
        link: function ($scope) {
            activate();

            function activate() {
                loadForm($scope.post.form);
            }

            function loadForm(form) {
                // Replace form with full object
                if (form) {
                    FormEndpoint.get({id: form.id}, function (form) {
                        $scope.post.form = form;
                    });
                    FormAttributeEndpoint.get({formId: form.id}, function (attributes) {
                        angular.forEach(attributes.results, function (value, key) {
                            if (value.label == 'Summary of Incidence' && $scope.post.values[value.key]) {
                                $scope.post.summary = $scope.post.values[value.key][0];
                            }
                        })
                    });
                }
            }
        }
    };
}
