module.exports = PostEditor;

PostEditor.$inject = [];

function PostEditor() {
    return {
        restrict: "E",
        scope: {
            post: "=",
            attributesToIgnore: "=",
            form: "=",
            postMode: "="
        },
        templateUrl: "templates/main/posts/modify/post-editor.html",
        controller: PostEditorController
    };
}

PostEditorController.$inject = [
    "$rootScope",
    "$scope",
    "$q",
    "$filter",
    "$location",
    "$translate",
    "moment",
    "PostEntity",
    "PostEndpoint",
    "PostEditService",
    "FormEndpoint",
    "FormStageEndpoint",
    "FormAttributeEndpoint",
    "UserEndpoint",
    "CommentEndpoint",
    "TagEndpoint",
    "Notify",
    "_",
    "PostActionsService",
    "WardEndpoint",
    "DistrictEndpoint",
    "RegionEndpoint",
    "AssignmentEndpoint",
    "MessageEndpoint",
    "DistributorEndpoint",
    "OutletEndpoint",
    "$http",
    "Util",
    "ModalService"
];

function PostEditorController(
    $rootScope,
    $scope,
    $q,
    $filter,
    $location,
    $translate,
    moment,
    postEntity,
    PostEndpoint,
    PostEditService,
    FormEndpoint,
    FormStageEndpoint,
    FormAttributeEndpoint,
    UserEndpoint,
    CommentEndpoint,
    TagEndpoint,
    Notify,
    _,
    PostActionsService,
    WardEndpoint,
    DistrictEndpoint,
    RegionEndpoint,
    AssignmentEndpoint,
    MessageEndpoint,
    DistributorEndpoint,
    OutletEndpoint,
    $http,
    Util,
    ModalService
) {
    // Setup initial stages container
    $scope.everyone = $filter("translate")("post.modify.everyone");
    $scope.isEdit = !!$scope.post.id;
    $scope.validationErrors = [];
    $scope.visibleStage = 1;
    $scope.enableTitle = true;

    $scope.setVisibleStage = setVisibleStage;
    $scope.fetchAttributesAndTasks = fetchAttributesAndTasks;

    $scope.allowedChangeStatus = allowedChangeStatus;

    $scope.deletePost = deletePost;
    $scope.canSavePost = canSavePost;
    $scope.savePost = savePost;
    $scope.cancel = cancel;
    $scope.publish = publish;

    $scope.displayDistricts = displayDistricts;
    $scope.displayWards = displayWards;
    $scope.displayAssignment = displayAssignment;
    $scope.processFormComment = processFormComment;
    $scope.displayOutlets = displayOutlets;
    $scope.saveOutlets = saveOutlets;
    $scope.feedBackMessage = {};
    $scope.mediaOutlets = {};
    $scope.inNextPage = false;
    $scope.showSecondPage = false;
    $scope.isMctForm = false;
    $scope.openNextpageModel = openNextpageModel;
    $scope.submitMore = submitMore;
    $scope.onlySubmitFirstPage = onlySubmitFirstPage;

    if ($rootScope.currentUser) {
        $scope.canPublishPost =
            $rootScope.currentUser.permissions.indexOf("Publish Posts") !== -1;
        var isNewIncident =
            $scope.post.status == "new" || $scope.post.status == "draft";
        $scope.canDeletePost = $scope.canPublishPost || isNewIncident;
    }

    // If is MCT form show all the fields.
    if ($scope.form.id == 1) {
        $scope.isMctForm = true;
    }

    if ($scope.post.id) {
        loadComments();
    }

    function loadComments() {
        CommentEndpoint.get({ post: $scope.post.id }).$promise.then(function(
            comments
        ) {
            $scope.allPostComments = comments;
        });
    }

    MessageEndpoint.get({ post: $scope.post.id }).$promise.then(function(
        feedback
    ) {
        $scope.feedback = feedback.results;
    });

    DistributorEndpoint.get().$promise.then(function(distributors) {
        $scope.distributors = distributors.results;
        var others = [
            {
                id: "others",
                name: "Other Distributor"
            }
        ];
        $scope.distributors = angular.extend($scope.distributors, others);
    });

    PostEndpoint.query().$promise.then(function(postsResponse) {
        $scope.posts = postsResponse.results;
    });

    var getComments = function() {
        var comment = {
            post_id: $scope.post.id,
            user_id: $rootScope.currentUser.userId
        };
        $scope.comment = comment;
    };
    if ($rootScope.currentUser) {
        getComments();
    }
    // process the form
    function processFormComment() {
        CommentEndpoint.save($scope.comment).$promise;
        $scope.allPostComments = CommentEndpoint.getFresh({
            post: $scope.post.id
        });
    }

    function submitMore() {
        ModalService.close();
        $scope.showSecondPage = true;
    }

    function onlySubmitFirstPage() {
        ModalService.close();
        savePost();
    }

    $scope.isRegionChoosen = false;
    $scope.isDistrictChoosen = false;
    $scope.showDistributors = true;

    $scope.assignment_post = {};

    if ($scope.post.id) {
        AssignmentEndpoint.query({ post: $scope.post.id }).$promise.then(
            function(assignment) {
                var assignment_post = assignment.results[0];
                if (assignment_post) {
                    $scope.assignment_post = assignment_post;
                }
            }
        );
    }

    UserEndpoint.query({
        role: ["admin", "MCT staff", "MCT Reviewers"]
    }).$promise.then(function(users) {
        $scope.users_to_assign = users.results;
    });

    activate();

    function activate() {
        TagEndpoint.query().$promise.then(function(results) {
            $scope.categories = results;
        });
        // Set bulk data import mode params
        if ($scope.postMode === "bulk_data_import") {
            if (_.contains($scope.attributesToIgnore, "title")) {
                $scope.enableTitle = false;
            }
        }

        $scope.post.form = $scope.form;
        $scope.fetchAttributesAndTasks($scope.post.form.id);
        //$scope.fetchStages($scope.post.form.id);

        RegionEndpoint.get().$promise.then(function(regions) {
            $scope.regions = regions.results;
        });

        if ($scope.post.ward) {
            WardEndpoint.get({ id: $scope.post.ward.id }).$promise.then(
                function(value) {
                    var ward = value;
                    $scope.post.ward_id = ward.id;
                    displayWards(ward.district.id);
                    DistrictEndpoint.get({
                        id: ward.district.id
                    }).$promise.then(function(value) {
                        var district = value;
                        $scope.locationDetails.districts = district.id;
                        RegionEndpoint.get({
                            id: district.region.id
                        }).$promise.then(function(value) {
                            var region = value;
                            $scope.locationDetails.region = region.id;
                            displayDistricts(region.id);
                        });
                    });
                }
            );
        }

        if ($scope.post.distributor && $scope.post.distributor.id) {
            displayOutlets($scope.post.distributor.id);
            $scope.post.distributor_id = $scope.post.distributor.id;
            $scope.post.outlet_id = $scope.post.outlet.id;
        }
    }

    function setVisibleStage(stageId) {
        $scope.visibleStage = stageId;
    }
    $scope.locationDetails = {};

    function displayAssignment(value) {}

    function openNextpageModel() {
        return ModalService.openUrl(
            "templates/main/posts/modify/submit-more-info.html",
            "post.more_info",
            false,
            $scope,
            true,
            false
        );
    }

    function displayOutlets(distributorId) {
        if (distributorId) {
            if (distributorId == "others") {
                $scope.showDistributors = false;
            } else {
                $scope.isDistributorChoosen = true;
                OutletEndpoint.get({
                    distributor: distributorId
                }).$promise.then(function(outlets) {
                    $scope.outlets = outlets.results;
                });
            }
        }
    }

    function saveOutlets(outletId) {
        if (outletId == "others") {
            $scope.isDistributorChoosen = false;
            $scope.onlyShowOutletInput = true;
        }
    }

    function displayDistricts(id) {
        if (id) {
            $scope.isRegionChoosen = true;
            DistrictEndpoint.get({ region: id }).$promise.then(function(value) {
                $scope.districts_region = value.results;
            });
        }
    }

    function displayWards(id) {
        if (id) {
            $scope.isDistrictChoosen = true;
            WardEndpoint.get({ district: id }).$promise.then(function(value) {
                $scope.wards_district = value.results;
            });
        }
    }

    function fetchAttributesAndTasks(formId) {
        $q
            .all([
                FormStageEndpoint.query({ formId: formId }).$promise,
                FormAttributeEndpoint.query({ formId: formId }).$promise
            ])
            .then(function(results) {
                var post = $scope.post;
                var tasks = _.sortBy(results[0], "priority");
                var attrs = _.chain(results[1])
                    .sortBy("priority")
                    .value();

                // If attributesToIgnore is set, remove those attributes from set of fields to display
                var attributes = [];
                _.each(attrs, function(attr) {
                    if (!_.contains($scope.attributesToIgnore, attr.key)) {
                        attributes.push(attr);
                    }
                });
                attributes = attributes.length ? attributes : attrs;

                // Initialize values on post (helps avoid madness in the template)
                attributes.map(function(attr) {
                    // @todo don't assign default when editing? or do something more sane
                    if (!$scope.post.values[attr.key]) {
                        if (attr.input === "location") {
                            // Prepopulate location fields from message location
                            if ($scope.post.values.message_location) {
                                $scope.post.values[attr.key] = angular.copy(
                                    $scope.post.values.message_location
                                );
                            } else {
                                $scope.post.values[attr.key] = [null];
                            }
                        } else if (attr.input === "number") {
                            $scope.post.values[attr.key] = [
                                parseInt(attr.default)
                            ];
                        } else if (
                            attr.input === "date" ||
                            attr.input === "datetime"
                        ) {
                            $scope.post.values[attr.key] = attr.default
                                ? [new Date(attr.default)]
                                : [new Date()];
                        } else {
                            $scope.post.values[attr.key] = [attr.default];
                        }
                    } else if (
                        attr.input === "date" ||
                        attr.input === "datetime"
                    ) {
                        // Date picker requires date object
                        // ensure that dates are preserved in UTC
                        if ($scope.post.values[attr.key][0]) {
                            $scope.post.values[attr.key][0] = moment(
                                $scope.post.values[attr.key][0]
                            ).toDate();
                        }
                    } else if (attr.input === "number") {
                        // Number input requires a number
                        if ($scope.post.values[attr.key][0]) {
                            $scope.post.values[attr.key][0] = parseFloat(
                                $scope.post.values[attr.key][0]
                            );
                        }
                    }
                });

                _.each(tasks, function(task) {
                    task.attributes = _.filter(attributes, function(attribute) {
                        return attribute.form_stage_id === task.id;
                    });
                });

                // If number of completed stages matches number of tasks - not including Post,
                // assume they're all complete, and just show the first task
                if (
                    post.completed_stages.length === tasks.length - 1 &&
                    tasks.length > 1
                ) {
                    $scope.setVisibleStage(tasks[1].id);
                } else {
                    // Get incomplete stages
                    var incompleteStages = _.filter(tasks, function(task) {
                        return !_.contains(post.completed_stages, task.id);
                    });

                    // Return lowest priority incomplete task - not including post
                    incompleteStages.length > 1
                        ? $scope.setVisibleStage(incompleteStages[1].id)
                        : "";
                }
                $scope.tasks = tasks;
            });
    }

    function canSavePost() {
        return PostEditService.validatePost(
            $scope.post,
            $scope.postForm,
            $scope.tasks
        );
    }

    function cancel() {
        var path = $scope.post.id ? "/posts/" + $scope.post.id : "/";
        $location.path(path);
    }

    function publish() {
        $scope.post.status = "published";
        savePost();
    }

    function deletePost(post) {
        PostActionsService.delete(post).then(function() {
            $location.path("/");
        });
    }

    function allowedChangeStatus() {
        return (
            $scope.post.allowed_privileges &&
            $scope.post.allowed_privileges.indexOf("change_status") !== -1
        );
    }

    function createNewDistributorsAndOutlets() {
        if (
            $scope.mediaOutlets.newDistributors &&
            $scope.mediaOutlets.newOutlet
        ) {
            var distributor = {
                name: $scope.mediaOutlets.newDistributors
            };
            DistributorEndpoint.save(distributor).$promise.then(function(
                newDistributor
            ) {
                $scope.post.distributor_id = newDistributor.id;
                var outlet = {
                    name: $scope.mediaOutlets.newOutlet,
                    distributor_id: newDistributor.id
                };
                OutletEndpoint.save(outlet).$promise.then(function(newOutlet) {
                    $scope.post.outlet_id = newOutlet.id;
                });
            });
        }
    }

    function createNewOutlet() {
        if ($scope.mediaOutlets.newOutlet) {
            var outlet = {
                name: $scope.mediaOutlets.newOutlet,
                distributor_id: mediaOutlets.distributor
            };
            OutletEndpoint.save(outlet).$promise.then(function(newOutlet) {
                $scope.post.outlet_id = newOutlet.id;
            });
        }
    }

    function savePost() {
        if (!$scope.canSavePost()) {
            Notify.error("post.valid.validation_fail");
            return;
        }

        $scope.saving_post = true;

        // Avoid messing with original object
        // Clean up post values object
        if (
            $scope.post.distributor_id == "others" &&
            $scope.mediaOutlets.newDistributors &&
            $scope.mediaOutlets.newDistributors
        ) {
            createNewDistributorsAndOutlets();
        }

        if (
            $scope.post.outlet_id == "others" &&
            $scope.mediaOutlets.newDistributors
        ) {
            createNewOutlet();
        }
        var post = PostEditService.cleanPostValues(angular.copy($scope.post));

        var request;
        if (post.id) {
            request = PostEndpoint.update(post);
        } else {
            request = PostEndpoint.save(post);
        }

        if ($scope.assignment_post) {
            $scope.saveAssignment($scope.assignment_post);
        }

        if ($scope.feedBackMessage.message) {
            $scope.feedBackMessage.post_id = $scope.post.id;
            MessageEndpoint.save($scope.feedBackMessage);
        }

        request.$promise.then(
            function(response) {
                console.log(response);
                var success_message =
                    response.status && response.status === "published"
                        ? "notify.post.save_success"
                        : "notify.post.save_success_review";

                if ($scope.post.mediaData) {
                    if ($scope.post.mediaData.post == undefined) {
                        $scope.post.mediaData.post_id = response.id;
                        $http
                            .put(
                                Util.apiUrl(
                                    "/media/" + $scope.post.mediaData.id
                                ),
                                $scope.post.mediaData,
                                {
                                    headers: {
                                        "Content-Type": undefined
                                    }
                                }
                            )
                            .then(function(response) {});
                    }
                }
                if (
                    response.id &&
                    response.allowed_privileges.indexOf("read") !== -1
                ) {
                    $scope.saving_post = false;
                    $scope.post.id = response.id;
                    Notify.notify(success_message, { name: $scope.post.title });
                    $location.path("/posts/" + response.id);
                } else {
                    $scope.saving_post = false;
                    Notify.notify(success_message, { name: $scope.post.title });
                    $location.path("/");
                }
            },
            function(errorResponse) {
                // errors
                var validationErrors = [];
                // @todo refactor limit handling
                _.each(errorResponse.data.errors, function(value, key) {
                    // Ultimately this should check individual status codes
                    // for the moment just check for the message we expect
                    if (value.title === "limit::posts") {
                        Notify.limit("limit.post_limit_reached");
                    } else {
                        validationErrors.push(value);
                    }
                });

                Notify.errors(_.pluck(validationErrors, "message"));

                $scope.saving_post = false;
            }
        );
    }

    $scope.saveAssignment = function(assignment) {
        if (assignment.id) {
            return AssignmentEndpoint.update(assignment).$promise;
        } else {
            // Enable notifications for new contacts by default
            assignment.post_id = $scope.post.id;
            return AssignmentEndpoint.save(assignment).$promise;
        }
    };
}
