module.exports = [
    '$http',
    'leafletData',
    'Geocoding',
    'Maps',
    '_',
    function ($http, leafletData, Geocoding, Maps, _) {

        return {
            restrict: 'E',
            replace: true,
            scope: {
                id: '@',
                name: '@',
                model: '=',
                required: '='
            },
            templateUrl: 'templates/main/posts/modify/location.html',
            controller: [
                '$window',
                '$scope',
                'Leaflet',
                function ($window, $scope, Leaflet) {
                    var markers = {},
                        mapName = $scope.id + '-map';
                    $scope.mapReady = false;

                    $scope.autocompleteOptions = {
                        componentRestrictions: {
                            country: 'tz'
                        },
                        types: ['geocode']
                    }
                    $scope.getCalled = function () {

                    }

                    $scope.$watch("searchLocationTerm", function (newVal) {
                        if (angular.isObject(newVal)) {
                            $scope.searchLocation()
                        }
                    });

                    angular.extend($scope, Maps.getInitialScope());

                    // Try to use value from settings
                    Maps.getAngularScopeParams().then(function (params) {
                        // Save initial center for reset
                        $scope.initialCenter = params.center;

                        // If we already have a location wipe the center
                        if ($scope.model) {
                            delete params.center;
                        }
                        // Then save params into scope
                        angular.extend($scope, params);

                        $scope.mapReady = true;
                    });

                    // init markers with current model value
                    if ($scope.model) {
                        markers = {
                            m1: {
                                lat: $scope.model.lat,
                                lng: $scope.model.lon,
                                focus: true,
                                draggable: true
                            }
                        };

                        $scope.center = {
                            lat: $scope.model.lat,
                            lng: $scope.model.lon,
                            zoom: 4
                        };
                    }

                    // leaflet map or location attribute
                    angular.extend($scope, {
                        markers: markers,

                        updateLatLon: function (lat, lon) {
                            $scope.model = {
                                lat: lat,
                                lon: lon
                            };
                        },

                        updateMarkerPosition: function (lat, lon) {
                            $scope.markers.m1 = {
                                lat: lat,
                                lng: lon,
                                focus: true,
                                draggable: true,
                                zoom: 14
                            };
                        },

                        centerMapTo: function (lat, lon) {
                            $scope.center = {
                                lat: lat,
                                lng: lon,
                                zoom: 14
                            };
                        },

                        searchLocation: function () {
                            var that = this;
                            $scope.processing = true;

                            var coordinates = [];

                            if (angular.isObject($scope.searchLocationTerm)) {
                                coordinates[0] = $scope.searchLocationTerm.geometry.location.lat();
                                coordinates[1] = $scope.searchLocationTerm.geometry.location.lng();
                                if ((coordinates.length > 0)) {

                                    _.each([
                                        'updateLatLon', 'updateMarkerPosition', 'centerMapTo'
                                    ], function (fn) {
                                        that[fn](coordinates[0], coordinates[1]);
                                    });
                                }
                                $scope.processing = false;

                            } else {

                                Geocoding.search($scope.searchLocationTerm).then(function (coordinates) {
                                    coordinates = coordinates;

                                    if ((coordinates.length > 0)) {
                                        _.each([
                                            'updateLatLon', 'updateMarkerPosition', 'centerMapTo'
                                        ], function (fn) {
                                            that[fn](coordinates[0], coordinates[1]);
                                        });
                                    }
                                    $scope.processing = false;
                                });
                            }

                        },

                        clear: function () {
                            $scope.model = null;
                            $scope.center = $scope.initialCenter;
                            $scope.markers = {};
                            $scope.searchLocationTerm = '';
                        }
                    });

                    leafletData.getMap(mapName).then(function (map) {
                        map.scrollWheelZoom.disable();
                        map.on('click', onMapClick);
                        function onMapClick(e) {
                            var wrappedLatLng = e.latlng.wrap(),
                                lat = wrappedLatLng.lat,
                                lon = wrappedLatLng.lng;

                            $scope.updateMarkerPosition(lat, lon);
                            $scope.updateLatLon(lat, lon);
                        }

                        // Add locate control, but only on https
                        if (window.location.protocol === 'https:' || window.location.hostname === 'localhost') {
                            Leaflet.control.locate({follow: true}).addTo(map);
                        }

                        // treate locationfound same as map click
                        map.on('locationfound', onMapClick);
                    });
                }
            ]
        };

    }
];
