module.exports = PostActionsDirective;

PostActionsDirective.$inject = [
    "PostEndpoint",
    "Notify",
    "$location",
    "$rootScope",
    "PostActionsService",
    "StatusEndpoint"
];
function PostActionsDirective(
    PostEndpoint,
    Notify,
    $location,
    $rootScope,
    PostActionsService,
    StatusEndpoint
) {
    return {
        restrict: "E",
        replace: true,
        scope: {
            post: "="
        },
        templateUrl: "templates/main/posts/common/post-actions.html",
        link: PostActionsLink
    };

    function PostActionsLink($scope) {
        $scope.deletePost = deletePost;
        $scope.updateStatus = updateStatus;

        activate();

        function activate() {
            StatusEndpoint.get().$promise.then(function(response) {
                $scope.statuses = response.results;
            });
            var canPublishPost =
                $rootScope.currentUser.permissions.indexOf("Publish Posts") !==
                -1;
            var isNewIncident =
                $scope.post.status == "new" || $scope.post.status == "draft";
            $scope.canDeletePost = canPublishPost || isNewIncident;
        }

        function deletePost() {
            PostActionsService.delete($scope.post).then(function() {
                $location.path("/views/list");
            });
        }

        function updateStatus(status) {
            $scope.post.status = status.name;

            PostEndpoint.update($scope.post).$promise.then(
                function() {
                    Notify.notify("notify.post.save_success", {
                        name: $scope.post.title
                    });
                },
                function(errorResponse) {
                    Notify.apiErrors(errorResponse);
                }
            );
        }
    }
}
