module.exports = DashboardAggregateController;

DashboardAggregateController.$inject = [
    '$timeout',
    '$scope',
    '$rootScope',
    '$translate',
    'moment',
    'PostFilters',
    'ReportEndpoint',
    'RegionEndpoint',
    'TagEndpoint',
    '_'
];

function DashboardAggregateController($timeout, $scope, $rootScope, $translate, moment, PostFilters, ReportEndpoint, RegionEndpoint, TagEndpoint, _) {
    $scope.filters = PostFilters.getFilters();

    $scope.year = new Date().getFullYear();
    $scope.filterByAllFactors = filterByAllFactors;

    $scope.activeMode = 'aggregate';

    function initilize(dateFrom, dateTo, categoryFilt, regionFilt) {
        $scope.filterCategories = {
            xAxisValue: 'category',
            yAxisValue: 'count',
            parameters: {
                id: 11,
                'parameters[from]': dateFrom,
                'parameters[to]': dateTo,
                'parameters[category]': categoryFilt,
                'parameters[region]': regionFilt
            }
        };
        $scope.filterCulpitType = {
            xAxisValue: 'culprit',
            yAxisValue: 'count',
            parameters: {
                id: 12,
                'parameters[from]': dateFrom,
                'parameters[to]': dateTo,
                'parameters[category]': categoryFilt,
                'parameters[region]': regionFilt
            }
        };
        $scope.filterRegionType = {
            xAxisValue: 'culprit',
            yAxisValue: 'count',
            parameters: {
                id: 13,
                'parameters[from]': dateFrom,
                'parameters[to]': dateTo,
                'parameters[category]': categoryFilt,
                'parameters[region]': regionFilt
            }
        };

        $scope.filterGender = {
            xAxisValue: 'gender',
            yAxisValue: 'count',
            parameters: {
                id: 10,
                'parameters[from]': dateFrom,
                'parameters[to]': dateTo,
                'parameters[category]': categoryFilt,
                'parameters[region]': regionFilt
            }
        };

        $scope.filterMedia = {
            xAxisValue: 'media',
            yAxisValue: 'count',
            parameters: {
                id: 16,
                'parameters[from]': dateFrom,
                'parameters[to]': dateTo,
                'parameters[category]': categoryFilt,
                'parameters[region]': regionFilt
            }
        };

        $scope.filterSeverity = {
            xAxisValue: 'severity',
            yAxisValue: 'count',
            parameters: {
                id: 14,
                'parameters[from]': dateFrom,
                'parameters[to]': dateTo,
                'parameters[category]': categoryFilt,
                'parameters[region]': regionFilt
            }
        };

        $scope.filterNewsType = {
            xAxisValue: 'news',
            yAxisValue: 'count',
            parameters: {
                id: 15,
                'parameters[from]': dateFrom,
                'parameters[to]': dateTo,
                'parameters[category]': categoryFilt,
                'parameters[region]': regionFilt
            }
        };
    }
    activate()
    function activate() {
        initilize($scope.from, $scope.to, $scope.categoryfilter, $scope.regionfilter)
    }

    function filterByAllFactors() {
        activate();
        $timeout(function () {
            $scope.$broadcast('someEvent');
        });
    }

    //get all the years in the database;
    ReportEndpoint.get({id: 2}).$promise.then(function (response) {
        var result = _.pluck(response.data, 'year');
        var unique = result.filter(function (item, i, ar) {
            return ar.indexOf(item) === i;
        });
        $scope.years = unique.map(Number);

    });

    RegionEndpoint.query().$promise.then(function (response) {
        $scope.regions = response.results
    })
    TagEndpoint.query().$promise.then(function (results) {
        $scope.categories = results;
    });

}
