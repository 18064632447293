angular.module('ushahidi.main', [
    'ushahidi.posts',
    'ushahidi.activity',
    'ushahidi.user',
    'ushahidi.dashboard'
]);

require('./posts/posts-module.js');
require('./activity/activity-module.js');
require('./user/user-module.js');
require('./dashboard/dashboard-module.js');
