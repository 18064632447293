module.exports = DashboardController;

DashboardController.$inject = [
    "$timeout",
    "$scope",
    "$rootScope",
    "$translate",
    "moment",
    "Features",
    "UserEndpoint",
    "ContactEndpoint",
    "PostFilters",
    "ReportEndpoint",
    "_"
];

function DashboardController(
    $timeout,
    $scope,
    $rootScope,
    $translate,
    moment,
    Features,
    UserEndpoint,
    ContactEndpoint,
    PostFilters,
    ReportEndpoint,
    _
) {
    $scope.filters = PostFilters.getFilters();

    $scope.year = new Date().getFullYear();
    $scope.activeMode = "summary";

    function initilize(year) {
        $scope.filterCategories = {
            xAxisValue: "category",
            yAxisValue: "count",
            height: 450,
            width: 450,
            parameters: {
                id: 2,
                "parameters[year]": year
            }
        };
        $scope.filterCulpitType = {
            xAxisValue: "culprit",
            yAxisValue: "count",
            height: 450,
            width: 450,
            parameters: {
                id: 3,
                "parameters[year]": year
            }
        };
        $scope.filterRegionType = {
            xAxisValue: "region",
            height: 450,
            width: 930,
            yAxisValue: "count",
            parameters: {
                id: 4,
                "parameters[year]": year
            }
        };

        $scope.filterIncidentCount = {
            xAxisValue: "year",
            height: 450,
            width: 450,
            yAxisValue: "count",
            parameters: {
                id: 8,
                "parameters[year]": year
            }
        };

        $scope.filterGender = {
            xAxisValue: "gender",
            height: 450,
            width: 450,
            yAxisValue: "count",
            parameters: {
                id: 1,
                "parameters[year]": year
            }
        };

        $scope.filterMedia = {
            xAxisValue: "media",
            yAxisValue: "count",
            height: 450,
            width: 450,
            parameters: {
                id: 7,
                "parameters[year]": year
            }
        };

        $scope.filterSeverity = {
            xAxisValue: "severity",
            yAxisValue: "count",
            height: 450,
            width: 450,
            parameters: {
                id: 5,
                "parameters[year]": year
            }
        };

        $scope.filterNewsType = {
            xAxisValue: "news",
            yAxisValue: "count",
            height: 450,
            width: 450,
            parameters: {
                id: 6,
                "parameters[year]": year
            }
        };
    }
    activate();
    function activate() {
        initilize($scope.year);
    }

    $scope.$watch("year", function() {
        activate();
        $timeout(function() {
            $scope.$broadcast("someEvent");
        });
    });

    //get all the years in the database;
    ReportEndpoint.get({ id: 8 }).$promise.then(function(response) {
        var result = _.pluck(response.data, "year");
        var unique = result.filter(function(item, i, ar) {
            return ar.indexOf(item) === i;
        });
        $scope.years = unique.map(Number);
        $scope.year = $scope.years[$scope.years.length - 1];
    });
}
