module.exports = ProfileUserController;

ProfileUserController.$inject = [
    '$scope',
    '$rootScope',
    '$translate',
    '$location',
    'moment',
    'Features',
    'UserEndpoint',
    'ContactEndpoint'
];

function ProfileUserController(
    $scope,
    $rootScope,
    $translate,
    $location,
    moment,
    Features,
    UserEndpoint,
    ContactEndpoint
) {
    $scope.activeMode = 'profile';
    $scope.contacts = null;

    // Redirect to home if not authorized
    if (!$rootScope.loggedin) {
        return $location.path('/');
    }

    activate();
    function activate() {
        UserEndpoint.get({id: 'me'}).$promise.then(function (userData) {
            $scope.user = userData;
        });
        ContactEndpoint.get({user: 'me'}).$promise.then(function (contactData) {
            $scope.contacts = contactData.results;
        });
    }

}
